var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showCharts)?_c('div',[(!_vm.pdfExportStyle)?_c('div',{staticClass:"col-4"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectFilterType),expression:"selectFilterType"}],staticClass:"form-control",attrs:{"name":"selectDate"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectFilterType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.selectPeriodDate]}},_vm._l((_vm.selectOptions),function(optionItem){return _c('option',{key:optionItem.value,domProps:{"value":optionItem.value}},[_vm._v(_vm._s(optionItem.text))])}),0),_c('div',{staticClass:"row pl-0"},[_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Von")]),_c('date-picker',{staticClass:"d-inline",attrs:{"name":"from","min-date":null,"value":_vm.from,"placeholder":"TT.MM.JJ"},on:{"input":_vm.onSelectDateFrom}})],1),_c('div',{staticClass:"col-6 pl-6"},[_c('span',[_vm._v("Bis")]),_c('date-picker',{staticClass:"d-inline",attrs:{"name":"to","min-date":null,"value":_vm.to,"placeholder":"TT.MM.JJ"},on:{"input":_vm.onSelectDateTo}})],1)])]):_vm._e(),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Zürs-Zone")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byZuersStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byZuersStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byZuersFileName,
                  },
                  svg: {
                    filename: _vm.byZuersFileName,
                  },
                  png: {
                    filename: _vm.byZuersFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byZuersStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Ausland")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byAbroadStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byAbroadStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byAbroadFileName,
                  },
                  svg: {
                    filename: _vm.byAbroadFileName,
                  },
                  png: {
                    filename: _vm.byAbroadFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byAbroadStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Ausland 2")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byAbroad2StatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byAbroad2StatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byAbroad2FileName,
                  },
                  svg: {
                    filename: _vm.byAbroad2FileName,
                  },
                  png: {
                    filename: _vm.byAbroad2FileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byAbroad2StatisticValues,
          }]}})],1)])]),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Vertragsart")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byContractTypeStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byContractTypeStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byContractTypeFileName,
                  },
                  svg: {
                    filename: _vm.byContractTypeFileName,
                  },
                  png: {
                    filename: _vm.byContractTypeFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byContractTypeStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Versicherungsnehmer")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byPolicyHolderStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byPolicyHolderStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byPolicyHolderFileName,
                  },
                  svg: {
                    filename: _vm.byPolicyHolderFileName,
                  },
                  png: {
                    filename: _vm.byPolicyHolderFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byPolicyHolderStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Art der Industriemontage")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byTypeOfAssemblyStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byTypeOfAssemblyStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byTypeOfAssemblyFileName,
                  },
                  svg: {
                    filename: _vm.byTypeOfAssemblyFileName,
                  },
                  png: {
                    filename: _vm.byTypeOfAssemblyFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byTypeOfAssemblyStatisticValues,
          }]}})],1)])]),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Art der Energie-Montage")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byTypeOfEnergyAssemblyStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byTypeOfEnergyAssemblyStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byTypeOfEnergyAssemblyFileName,
                  },
                  svg: {
                    filename: _vm.byTypeOfEnergyAssemblyFileName,
                  },
                  png: {
                    filename: _vm.byTypeOfEnergyAssemblyFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byTypeOfEnergyAssemblyStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Gebrauchte Sachen als Montageobjekt (TK 7106)")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byTk7106StatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byTk7106StatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byTk7106FileName,
                  },
                  svg: {
                    filename: _vm.byTk7106FileName,
                  },
                  png: {
                    filename: _vm.byTk7106FileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byTk7106StatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Gefahrengrad")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byDangerLevelStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byDangerLevelStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byDangerLevelFileName,
                  },
                  svg: {
                    filename: _vm.byDangerLevelFileName,
                  },
                  png: {
                    filename: _vm.byDangerLevelFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byDangerLevelStatisticValues,
          }]}})],1)])]),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-6 d-flex mb-5 justify-content-center"},[_c('h4',[_vm._v("Dauer der Montage (durchschnittlich): "+_vm._s(_vm.avgDurationOfAssembly))])]),_c('div',{staticClass:"col-6 d-flex mb-5 justify-content-center"},[_c('h4',[_vm._v("Dauer des Probebetriebs (durchschnittlich): "+_vm._s(_vm.avgDurationOfTrial))])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }