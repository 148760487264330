const biogasModule = {
  namespaced: true,
  state: {
    selectedBu: false,
  },
  getters: {
    getSelectedBu: (state) => state.selectedBu
  },
  mutations: {
    setSelectedBu: (state, value) => {
      state.selectedBu = value;
    },
  }
};

export default biogasModule;
