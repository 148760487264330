







































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import ProductsChart from '@/views/charts/products-chart.vue';
import MachineChart from '@/views/charts/machine-chart.vue';
import BuildingChart from '@/views/charts/building-chart.vue';
import WindTurbinesChart from '@/views/charts/wind-turbines-chart.vue';
import BiogasChart from '@/views/charts/biogas-chart.vue';
import PhotovoltaicsChart from '@/views/charts/photovoltaics-chart.vue';
import MontageChart from '@/views/charts/montage-chart.vue';
import apiBackend from '@/service/api-insurance';
import { downloadFile } from '@/helper/functions';
import { dateService } from '@/service/date-service';

export default Vue.extend({
  components: {
    MontageChart,
    PhotovoltaicsChart,
    BiogasChart,
    WindTurbinesChart,
    BuildingChart,
    MachineChart,
    ProductsChart,
  },
  props: ['OnLoggedIn'],
  data() {
    return {
      generalStatisticData: null,
      machineStatisticData: null,
      buildingStatisticData: null,
      windStatisticData: null,
      biogasStatisticData: null,
      photovoltaicsStatisticData: null,
      montageStatisticData: null,

      productUsageStatisticOpened: true,
      productUsageStatisticLoaded: false,
      productUsageExportData: {
      } as any,

      machineStatisticOpened: false,
      machineStatisticLoaded: false,
      machineExportData: {
      } as any,

      buildingStatisticOpened: false,
      buildingStatisticLoaded: false,
      buildingExportData: {
      } as any,

      windTurbinesStatisticOpened: false,
      windTurbinesStatisticLoaded: false,
      windTurbinesExportData: {
      } as any,

      biogasStatisticOpened: false,
      biogasStatisticLoaded: false,
      biogasExportData: {
      } as any,

      photovoltaicsStatisticOpened: false,
      photovoltaicsStatisticLoaded: false,
      photovoltaicsExportData: {
      } as any,

      montageStatisticOpened: false,
      montageStatisticLoaded: false,
      montageExportData: {
      } as any,

      pdfStyles: false,
      startGeneratePdf: false,
      startGenerateExcel: false,
    };
  },
  computed: {
    allStatisticLoaded(): boolean {
      return this.machineStatisticLoaded &&
        this.buildingStatisticLoaded &&
        this.windTurbinesStatisticLoaded &&
        this.biogasStatisticLoaded &&
        this.photovoltaicsStatisticLoaded &&
        this.montageStatisticLoaded;
    },
    readyGeneratePdf(): boolean {
      return this.allStatisticLoaded && this.startGeneratePdf;
    },
    readyGenerateExcel(): boolean {
      return this.allStatisticLoaded && this.startGenerateExcel;
    },
    exportExcelData(): object {
      return {
        machine: this.machineExportData,
        building: this.buildingExportData,
        windTurbines: this.windTurbinesExportData,
        biogas: this.biogasExportData,
        photovoltaics: this.photovoltaicsExportData,
        montage: this.montageExportData,
      };
    },
  },
  mounted() {
    const from = '';
    const to = dateService.toStringSqlFormat(dateService.getCurrentDate());

    apiBackend.allStatistic(from, to).then((response: any) => {
      const { data } = response;

      this.generalStatisticData = data.general;
      this.machineStatisticData = data.individual;
      this.buildingStatisticData = data.building;
      this.windStatisticData = data.wind;
      this.biogasStatisticData = data.biogas;
      this.photovoltaicsStatisticData = data.photovoltaics;
      this.montageStatisticData = data.montage;
    });
  },
  methods: {
    openAllSections() {
      this.machineStatisticOpened = true;
      this.buildingStatisticOpened = true;
      this.windTurbinesStatisticOpened = true;
      this.biogasStatisticOpened = true;
      this.photovoltaicsStatisticOpened = true;
      this.montageStatisticOpened = true;
    },
    exportToPDF() {
      this.$toasted.success('Export gestartet, bitte warten');
      this.startGeneratePdf = true;

      this.openAllSections();
    },
    exportToExcel() {
      this.$toasted.success('Export gestartet, bitte warten');
      this.startGenerateExcel = true;

      this.openAllSections();
    },
    onProductUsageLoadedData(value: boolean, data: any) {
      this.productUsageStatisticLoaded = value;
      this.productUsageExportData = data;
    },
    onBiogasLoadedData(value: boolean, data: any) {
      this.biogasStatisticLoaded = value;
      this.biogasExportData = data;
    },
    onMachineLoadedData(value: boolean, data: any) {
      this.machineStatisticLoaded = value;
      this.machineExportData = data;
    },
    onBuildingLoadedData(value: boolean, data: any) {
      this.buildingStatisticLoaded = value;
      this.buildingExportData = data;
    },
    onWindTurbinesLoadedData(value: boolean, data: any) {
      this.windTurbinesStatisticLoaded = value;
      this.windTurbinesExportData = data;
    },
    onPhotovoltaicsLoadedData(value: boolean, data: any) {
      this.photovoltaicsStatisticLoaded = value;
      this.photovoltaicsExportData = data;
    },
    onMontageLoadedData(value: boolean, data: any) {
      this.montageStatisticLoaded = value;
      this.montageExportData = data;
    },
    exportStatistic() {
      apiBackend.exportStatisticData(this.exportExcelData).then((response) => {
        downloadFile(response.data, 'Statistik.xlsx');
        this.startGenerateExcel = false;

        this.$toasted.success('Statistikdatei wurde heruntergeladen');
      });
    },
    generatePdf() {
      const filename = 'Statistic.pdf';
      this.pdfStyles = true;

      setTimeout(() => {
        const element = document.getElementById('element-to-convert');

        if (element) {
          element.style.width = '1800px';

          html2pdf(element, {
            margin: 1,
            filename,
            jsPDF: {
              unit: 'pt',
              format: 'a4',
              orientation: 'p',
              putOnlyUsedFonts: true,
            },
            html2canvas: {
              width: 1800,
            },
          }).then((response) => {
            this.pdfStyles = false;
            this.startGeneratePdf = false;
            element.style.width = '';

            this.$toasted.success('Statistikdatei wurde heruntergeladen');
          });
        }
      },
      5000);
    },
  },
  watch: {
    readyGeneratePdf(value: boolean) {
      if (value) {
        this.generatePdf();
      }
    },
    readyGenerateExcel(value: boolean) {
      if (value) {
        this.exportStatistic();
      }
    },
  },
});

