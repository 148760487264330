import biogasModule from '@/store/modules/by-types/biogas-module';

const insuranceModule = {
  namespaced: true,
  modules: {
    biogasModule
  }
};

export default insuranceModule;
