var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showCharts)?_c('div',[(!_vm.pdfExportStyle)?_c('div',{staticClass:"col-4"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectFilterType),expression:"selectFilterType"}],staticClass:"form-control",attrs:{"name":"selectDate"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectFilterType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.selectPeriodDate]}},_vm._l((_vm.selectOptions),function(optionItem){return _c('option',{key:optionItem.value,domProps:{"value":optionItem.value}},[_vm._v(_vm._s(optionItem.text))])}),0),_c('div',{staticClass:"row pl-0"},[_c('div',{staticClass:"col-6"},[_c('span',[_vm._v("Von")]),_c('date-picker',{staticClass:"d-inline",attrs:{"name":"from","min-date":null,"value":_vm.from,"placeholder":"TT.MM.JJ"},on:{"input":_vm.onSelectDateFrom}})],1),_c('div',{staticClass:"col-6 pl-6"},[_c('span',[_vm._v("Bis")]),_c('date-picker',{staticClass:"d-inline",attrs:{"name":"to","min-date":null,"value":_vm.to,"placeholder":"TT.MM.JJ"},on:{"input":_vm.onSelectDateTo}})],1)])]):_vm._e(),_c('div',{staticClass:"d-flex mb-5 justify-content-center"},[_c('div',[_c('h4',[_vm._v("Durchschnittlicher Risikofaktor: "+_vm._s(_vm.byAvgRisk))])])]),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Zürs-Zone")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byZuersStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byZuersStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byZuersFileName,
                  },
                  svg: {
                    filename: _vm.byZuersFileName,
                  },
                  png: {
                    filename: _vm.byZuersFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byZuersStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Naturgefahrenklasse")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byNaturalHazardsClassStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byNaturalHazardsClassStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byNaturalHazardsClassFileName,
                  },
                  svg: {
                    filename: _vm.byNaturalHazardsClassFileName,
                  },
                  png: {
                    filename: _vm.byNaturalHazardsClassFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byNaturalHazardsClassStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Art der Anlage")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byNumberOfSystemStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byNumberOfSystemStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byNumberOfSystemFileName,
                  },
                  svg: {
                    filename: _vm.byNumberOfSystemFileName,
                  },
                  png: {
                    filename: _vm.byNumberOfSystemFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byNumberOfSystemStatisticValues,
          }]}})],1)])]),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Mitversicherung Softwareversicherung/Datenversicherung")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byCoinsuranceSoftwareStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byCoinsuranceSoftwareStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byCoinsuranceSoftwareFileName,
                  },
                  svg: {
                    filename: _vm.byCoinsuranceSoftwareFileName,
                  },
                  png: {
                    filename: _vm.byCoinsuranceSoftwareFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byCoinsuranceSoftwareStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Auslagerung in einen anderen Gefahren/Feuerbereich")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.ByFireAreaSoftwareStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.ByFireAreaStatisticLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.ByFireAreaFileName,
                  },
                  svg: {
                    filename: _vm.ByFireAreaFileName,
                  },
                  png: {
                    filename: _vm.ByFireAreaFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.ByFireAreaStatisticValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Lagerung der Daten in Datensicherheitsschrank")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byStorageOfDataStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byStorageOfDataLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byStorageOfDataFileName,
                  },
                  svg: {
                    filename: _vm.byStorageOfDataFileName,
                  },
                  png: {
                    filename: _vm.byStorageOfDataFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byStorageOfDataValues,
          }]}})],1)])]),_c('div',{staticClass:"mb-5",class:'d-flex'},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Vorschadenhistorie")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byPreviousLossHistoryStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byPreviousLossHistoryLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byPreviousLossHistoryFileName,
                  },
                  svg: {
                    filename: _vm.byPreviousLossHistoryFileName,
                  },
                  png: {
                    filename: _vm.byPreviousLossHistoryFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byPreviousLossHistoryValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Vier-Augen-Prinzip")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byFourEyesPrincipeStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byFourEyesPrincipeLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byFourEyesPrincipeFileName,
                  },
                  svg: {
                    filename: _vm.byFourEyesPrincipeFileName,
                  },
                  png: {
                    filename: _vm.byFourEyesPrincipeFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byFourEyesPrincipeValues,
          }]}})],1)]),_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"mt-3"},[_vm._v("Eingabelogik")]),_c('div',{staticClass:"col-12"},[_c('apexchart',{ref:"treemap",attrs:{"width":"100%","type":"bar","options":{
            title: {
                text: 'Alle Vorgänge: ' + _vm.byInputLogicStatisticTotal,
                style: {
                    fontSize: '21px',
                    fontWeight: 400,
                }
            },
            xaxis: {
              categories: _vm.byInputLogicLabels,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: _vm.byInputLogicFileName,
                  },
                  svg: {
                    filename: _vm.byInputLogicFileName,
                  },
                  png: {
                    filename: _vm.byInputLogicFileName,
                  },
                },
                tools: {
                  download: '📥',
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
          },"series":[{
            data:_vm.byInputLogicValues,
          }]}})],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }