import { DateTime } from 'luxon';

export const SQL_FORMAT = 'yyyy-LL-dd HH:mm';

export const dateService = {
  getCurrentDate() {
    return new Date(DateTime.now());
  },
  getStartOfYearDate() {
    return new Date(DateTime.now().startOf('year'));
  },
  getEndOfYearDate() {
    return new Date(DateTime.now().endOf('year'));
  },
  getStartOfMonthDate() {
    return new Date(DateTime.now().startOf('month'));
  },
  getEndOfMonthDate() {
    return new Date(DateTime.now().endOf('month'));
  },
  getStartOfWeekDate() {
    return new Date(DateTime.now().startOf('week'));
  },
  getEndOfWeekDate() {
    return new Date(DateTime.now().endOf('week'));
  },
  getStartOfDayDate() {
    return new Date((new Date()).setHours(0, 0, 0));
  },
  getEndOfDayDate() {
    return new Date((new Date()).setHours(23, 59, 59));
  },
  toStringSqlFormat(date: Date) {
    return DateTime.fromJSDate(date).toFormat(SQL_FORMAT);
  },
};
