/* eslint-disable */
// @ts-ignore

import {
  ASSEMBLY_INSURANCE_KEY,
  CASE_STATUS,
  PHOTOVOLTAIK_INSURANCE,
  WINDENERGIEANLAGEN_INSURANCE_KEY,
} from '@/constant/constants';
import conditionsFiles from '@/config/conditions-files';
import InsuranceTab from "@/model/tabs";
import tabs from "@/components/tabs.vue";
import { DuplicateElement } from '@/model/DuplicateElement';
import moment from 'moment';

function getProductNameHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return 'individual-insurance';
    default:
      return '';
  }
}
function getProductPageBeforePriceTabHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return 'individual-insurance-risk';
    case 'CONSTRUCTION_WORK_INSURANCE':
      return 'bauleistung-calc-2a-ABN';
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return 'bauleistung-calc-2b-ABU';
    case WINDENERGIEANLAGEN_INSURANCE_KEY:
      return 'windenergieanlagen-insurance-tarif';
    case ASSEMBLY_INSURANCE_KEY:
      return 'assembly-insurance-tarif';
    case PHOTOVOLTAIK_INSURANCE:
      return 'photovoltaics-insurance-risk';
    default:
      return '';
  }
}
function getProductNameForPricePageHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return 'individual-insurance';
    default:
      return '';
  }
}
function getNextRouteNameByKeyToCustomerDataHelper(key: string): string {
  let path = '';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-customer-data';
      break;
    default:
      break;
  }
  return path;
}

function getNextRouteNameByKeyToCustomerGeneralHelper(key: string): string {
  let path = 'individual-insurance-customer-general';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-customer-general';
      break;
    default:
      break;
  }
  return path;
}
function getNextRouteNameByKeyToPricePageHelper(key: any) {
  let path = '';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-price-card';
      break;
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      path = 'individual-insurance-price-card';
      break;
    case 'CONSTRUCTION_WORK_INSURANCE':
      path = 'bauleistung-insurance-price-card';
      break;
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      path = 'bauleistung-insurance-price-card';
      break;
    case 'BIOGAS_INSURANCE':
      path = 'biogas-insurance-price-card';
      break;
    default:
      break;
  }
  return path;
}
function getNextRouteNameByKeyToProposalPolicyHelper(key: any) {
  let path = '';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-proposal-policy';
      break;
    default:
      break;
  }
  return path;
}
function getLinkHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return '/individual-insurance';
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      return '/individual-insurance';
    case 'CONSTRUCTION_WORK_INSURANCE':
      return '/bauleistung-insurance';
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return '/bauleistung-insurance';
    case 'ELEKTRONIK_INSURANCE':
      return '/elektronik-insurance';
    case 'BIOGAS_INSURANCE':
      return '/biogas-insurance';
    case 'WINDENERGIEANLAGEN_INSURANCE':
      return '/windenergieanlagen-insurance';
    case 'ASSEMBLY_INSURANCE':
      return '/assembly-insurance';
    default:
      return '/products';
  }
}
function getTabInsuranceForms(key: string, params: any = null): object {
  let insuranceForms = {
    1: {
      name: 'Kalkulationsblatt',
      path: '/',
    },
    2: {
      name: 'Risikofaktoren',
      path: '/risk',
    },
  };
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'individual-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'individual-insurance-calc',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'individual-insurance-risk',
        },
      };
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'individual-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'machines-power-plants-calc',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'individual-insurance-risk',
        },
      };
    case 'CONSTRUCTION_WORK_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'bauleistung-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'bauleistung-calc-2a-ABN',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'bauleistung-insurance-risk',
        },
      };
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'bauleistung-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'bauleistung-calc-2b-ABU',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'bauleistung-insurance-risk',
        },
      };
    case 'ELEKTRONIK_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'elektronik-insurance',
        },
        2: {
          name: 'ELEK - Einzel',
          path: 'elektronik-insurance-ELEKEinzel',
        },
        3: {
          name: 'ELEK - Ergänzungstarif',
          path: 'elektronik-insurance-ELEKErganzungstarif',
        },
        4: {
          name: 'ELEK - Pauschal',
          path: 'elektronik-insurance-ELEKPauschal',
        },
        5: {
          name: 'Praxispolice',
          path: 'elektronik-insurance-Praxispolice',
        },
      };
    case 'WINDENERGIEANLAGEN_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'windenergieanlagen-insurance',
        },
        2: {
          name: 'EEWEA Tarifkalkulation',
          path: 'windenergieanlagen-insurance-tarif',
        },
      };
    case 'BIOGAS_INSURANCE': {
      const biogasMenuData = {
        1: {
          name: 'Grunddaten',
          path: 'biogas-insurance',
        },
        2: {
          name: 'EEBIO Tarifkalkulation',
          path: 'biogas-insurance-tarif',
        }
      };

      if (params?.selectedBu) {
        biogasMenuData[3] = {
          name: 'Risikoerfassung BU',
          path: 'biogas-insurance-risk',
        };
      }

      return biogasMenuData;
    }
    case 'ASSEMBLY_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'assembly-insurance',
        },
        2: {
          name: 'MON Tarifkalkulation',
          path: 'assembly-insurance-tarif',
        },
      };
    case PHOTOVOLTAIK_INSURANCE:
      return {
        1: {
          name: 'Grunddaten',
          path: 'photovoltaics-basic',
        },
        2: {
          name: 'EEPV nach ABE',
          path: 'photovoltaics-insurance-EEPV',
        },
        3: {
          name: 'BU ABE',
          path: 'photovoltaics-insurance-BU-ABE',
        },
        4: {
          name: 'EEPV nach ABPV',
          path: 'photovoltaics-insurance-EEPV-ABPV',
        },
        5: {
          name: 'Risikofaktoren',
          path: 'photovoltaics-insurance-risk',
        }
      };
    default:
      return insuranceForms;
  }
}

function getTabPriceForms(key: string, params: any = null): object {
  let PriceForms = {
      1: {
        name: 'Preis',
        path: 'price-card',
      },
    };
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'individual-insurance-price-card',
        },
      };
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'individual-insurance-price-card',
        },
      };
    case 'CONSTRUCTION_WORK_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'bauleistung-insurance-price-card',
        },
      };
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'bauleistung-insurance-price-card',
        },
      };
    case 'ELEKTRONIK_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'elektronik-insurance-price-card',
        },
      };
    case 'WINDENERGIEANLAGEN_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'windenergieanlagen-insurance-price-card',
        },
      };
    case 'BIOGAS_INSURANCE': {
      const biogasPriceMenu = {
        1: {
          name: 'Preis',
          path: 'biogas-insurance-price-card',
        },
      };

      if (params?.selectedBu) {
        biogasPriceMenu[2] = {
          name: 'Ergebnisseite BU',
          path: 'biogas-insurance-results-page',
        };
      }

      return biogasPriceMenu;
    }
    case 'ASSEMBLY_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'assembly-insurance-price-card',
        },
      };
    case PHOTOVOLTAIK_INSURANCE:
      return {
         1: {
           name: 'Ergebnisseite',
           path: 'photovoltaics-price-card',
         },
      };
    default:
      return PriceForms;
  }
}

//unnecessary functions that may be useful later - start
// function getTabcustomerForms(key: string): object {
//   let customerForms = {
//     1: {
//       name: 'Kundendaten',
//       path: 'individual-insurance-customer-data',
//     },
//   };
//   return customerForms;
// }
// function getTabPolice(key: string): object {
//   let policeTab = {
//     1: {
//       name: 'Vorschlag / Police',
//       path: 'individual-insurance-proposal-policy',
//     },
//   };
//   return policeTab;
// }
// function getTabFiles(key: string): object {
//   let filesTab = {
//     1: {
//       name: 'Bedingungen / Dateien',
//       path: '/',
//     },
//   };
//   return filesTab;
// }
//unnecessary functions that may be useful later - end


type tabs = {
  insuranceForms: any;
  priceForms: any;
  // customerForms: any;
  // police: any;
  // files: any;
};

type dateDifference = {
  value: number,
  period: 'Tage' | 'Monate' | 'Wochen'
};

function getTabsHelper(key: string, params: any = null): tabs {
  return {
    insuranceForms: getTabInsuranceForms(key, params),
    priceForms: getTabPriceForms(key, params),
    // customerForms: getTabcustomerForms(key),
    // police: getTabPolice(key),
    // files: getTabFiles(key),
  }
}
// function getActiveTabsHelper(key: string, activeTabsIndex: any): object {
//   let tabs = getTabInsuranceForms(key);
//   result = {};
//   activeTabsIndex.forEach((element: any, index) => {
//     result['index'] = tabs['index']
//   });
//   return result;
// }
function getTabInfoHelper(tab: InsuranceTab, tabs: object): InsuranceTab {
  const result: InsuranceTab = {
    groupId: tab.groupId,
    tabId: tab.tabId,
    name: tabs[tab.groupId][tab.tabId].name,
    path: tabs[tab.groupId][tab.tabId].path,
  };
  return result;
}

function getTabGroupIdNumberHelper(groupId) {
  switch (groupId) {
    case 'insuranceForms':
      return 1;
    case 'priceForms':
      return 2;
    // case 'customerForms':
    //   return 3;
    // case 'police':
    //   return 4;
    // case 'files':
    //   return 5;
    default:
      return 0;
  }
}

function getConditionsFilesHelper(key: string): object {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return conditionsFiles.INDIVIDUAL_INSURANCE;
    default:
      return {};
  }
}

function recursiveFillData(target, source) {
  if (typeof target !== 'object' || target === null || typeof source !== 'object' || source === null) {
    // If either target or source is not an object or is null, stop the recursion
    return;
  }

  for (const key in target) {
    if (Object.prototype.hasOwnProperty.call(target, key)) {
      if ((typeof target[key] === 'string' || typeof target[key] === 'number' || typeof target[key] === 'boolean' ||  target[key] === null) && source.hasOwnProperty(key)) {
        if (typeof target[key] === 'number' || target[key] === null) {
          target[key] = Number(source[key]);
        } else {
          target[key] = source[key];
        }
      } else if (typeof target[key] === 'object' || Array.isArray(target[key])) {
        if (source.hasOwnProperty(key)) {
          recursiveFillData(target[key], source[key]);
        }
      }
    }
  }
}
//
// function recursiveFillData(target, source) {
//   for (const key in target) {
//     if ((typeof target[key] === 'string' || (target[key] === null)) && source.hasOwnProperty(key)) {
//       if(typeof target[key] === 'number') {
//         target[key] = Number(source[key]);
//       } else {
//         target[key] = source[key];
//       }
//     } else if (typeof target[key] === 'object' || Array.isArray(target[key])) {
//       if (source.hasOwnProperty(key)) {
//         recursiveFillData(target[key], source[key]);
//       }
//     }
//   }
// }

function unselectFirstElement(list: object[], fieldName = 'isSelected'): void {
  const unselected = list.filter(item => !item[fieldName]);

  if (list.length > 0 && unselected.length === 0) {
    list[0][fieldName] = false;
  }
}

const duplicateDataItems = (insertItem: DuplicateElement, dataItems: DuplicateElement[], fillData?: boolean) => {
  const selectedElements = dataItems.filter((itm) => itm.isSelected);

  if (fillData && selectedElements.length > 0) {
    dataItems.push(...copyObject(selectedElements));
  }

  if (!fillData) {
    dataItems.push(...[copyObject(insertItem)]);
  }
};

const duplicateDataItemsWithoutSelect = (insertItem: any, dataItems: any[]) => {
  dataItems.push(JSON.parse(JSON.stringify(insertItem)));
};

const differenceBetweenDates = (beginDate: string, endDate: string): dateDifference => {
  const dateBegin = new Date(beginDate);
  const dateEnd = new Date(endDate);

  const monthOfBeginDate = dateBegin.getMonth();
  const monthOfEndDate = dateEnd.getMonth();

  const dateBeginMoment = moment([dateBegin.getFullYear(), monthOfBeginDate, dateBegin.getDate()]);
  const dateEndMoment = moment([dateEnd.getFullYear(), monthOfEndDate, dateEnd.getDate()]);

  const halfMonthFractional = 0.5;

  let diffInDays = Math.abs(dateEndMoment.diff(dateBeginMoment, 'days'));
  let diffInWeeks = Math.abs(dateEndMoment.diff(dateBeginMoment, 'weeks'));
  let diffInMonths = Math.abs(dateEndMoment.diff(dateBeginMoment, 'months'));
  const wholeMonths = Math.floor(diffInWeeks / 4);
  const duration = moment.duration(dateEndMoment.diff(dateBeginMoment)).asMonths();
  const fractional = duration - wholeMonths;
  const weeksOver = diffInWeeks % 4;

  if (Number.isNaN(diffInDays) || Number.isNaN(diffInWeeks) || Number.isNaN(diffInMonths) || diffInDays === 0) {
    return {
      value: 0,
      period: 'Wochen'
    };
  }

  if (diffInDays < 7 || diffInWeeks === 0) {
    return {
      value: 1,
      period: 'Wochen'
    };
  }

  if (diffInWeeks < 4) {
    diffInWeeks++;

    return {
      value: diffInWeeks,
      period: 'Wochen'
    };
  }

  if (diffInWeeks === 4) {
    if (monthOfBeginDate === monthOfEndDate) {
      return {
        value: diffInWeeks,
        period: 'Wochen'
      };
    }

    return {
      value: 1,
      period: 'Monate'
    };
  }

  if (wholeMonths > diffInMonths) {
    return {
      value: wholeMonths,
      period: 'Monate'
    };
  }

  if (weeksOver > 0 && fractional <= halfMonthFractional) {
    diffInMonths += 0.5
  }

  if (fractional > halfMonthFractional || weeksOver === 0 && wholeMonths > diffInMonths) {
    diffInMonths++;
  }

  return {
    value: diffInMonths,
    period: 'Monate'
  };
};

const calculateClaimsRequirementPremium = (
  totalDamage: number,
  totalReserves: number,
  damageHistoryObservationPeriod: number,
  targetLossPercents: number
): number => {
  const targetLossRatio = targetLossPercents / 100;

  if (damageHistoryObservationPeriod !== 0 && targetLossRatio !== 0) {
    return ((totalDamage + totalReserves) / damageHistoryObservationPeriod) / targetLossRatio;
  }

  return 0;
};

const copyObject = (object: any): any => {
  return JSON.parse(JSON.stringify(object));
};

function transformNumber(value, precision = 2) {
  // Ensure value is a number
  const numericValue = Number(value);

  // Check if the numericValue is a valid number
  if (!isNaN(numericValue)) {
    // Use toFixed to round to the specified precision and then convert back to a number
    return Number(numericValue.toFixed(precision));
  } else {
    // Handle the case where the input is not a valid number
    console.error(`Invalid number: ${value}`);
    return NaN;
  }
}

function transformToCurrencyStr(value: number): string {
  return value.toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' EUR'
}

function groupByKey(items: any[], key: string, additionalNoneSelectedValues: any[] = []): any {
  return items.reduce((accumulator, currentValue) => {
    const accumulatorElement = accumulator;

    const noneSelectedValues: any[] = [null, 'null']

    const keyName = noneSelectedValues
      .concat(additionalNoneSelectedValues)
      .includes(currentValue[key]) ?
        'Nicht ausgewählt' :
        currentValue[key];

    if (!accumulatorElement[keyName]) {
      accumulatorElement[keyName] = +currentValue.insurance_count;
    } else {
      accumulatorElement[keyName] += +currentValue.insurance_count;
    }

    return accumulatorElement;
  }, {
  });
}

function onCurrencyFocus(param) {
  const idElement = param?.target?.attributes?.id?.value;

  if (idElement) {
    const target = document.getElementById(idElement) as any;

    const comaPosition = target?.value ? String(target.value).indexOf(',') : -1;

    if (target &&
      target.selectionStart === 0 &&
      target.selectionStart !== null &&
      comaPosition !== -1
    ) {
      target.focus();
      target.setSelectionRange(comaPosition, comaPosition);
    }
  }
}

function downloadFile(data: any, fileName: string) {
  const fileURL = window.URL.createObjectURL(new Blob([data]));

  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
}

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

function mapBoolValues(value: any): any {
  if (value === 'true') {
    return 'Ja';
  }

  if (value === 'false') {
    return 'Nein';
  }

  return value;
}

function getStatisticData(items: any, groupKey: string) {
  const values: any[] = [];
  const labels: any[] = [];
  const excelData: any[] = [];
  let statisticTotal = 0;

  const transformedNameItems = groupByKey(items, groupKey);

  Object.keys(transformedNameItems).forEach((key) => {
    const keyName = mapBoolValues(key)

    labels.push(`${keyName}: ${transformedNameItems[key]}`);
    values.push(transformedNameItems[key]);

    statisticTotal += transformedNameItems[key];

    excelData.push({
      name: key,
      value: transformedNameItems[key],
    });
  });

  return {
    values,
    labels,
    statisticTotal,
    excelData,
  };
}

export  {
  getProductNameHelper,
  getNextRouteNameByKeyToCustomerDataHelper,
  getNextRouteNameByKeyToCustomerGeneralHelper,
  getProductNameForPricePageHelper,
  getNextRouteNameByKeyToPricePageHelper,
  getLinkHelper,
  getTabsHelper,
  getNextRouteNameByKeyToProposalPolicyHelper,
  getConditionsFilesHelper,
  getProductPageBeforePriceTabHelper,
  getTabInfoHelper,
  getTabGroupIdNumberHelper,
  unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  recursiveFillData,
  differenceBetweenDates,
  calculateClaimsRequirementPremium,
  copyObject,
  transformNumber,
  tabs,
  transformToCurrencyStr,
  groupByKey,
  onCurrencyFocus,
  downloadFile,
  deepEqual,
  getStatisticData,
};
