


















































import Vue from 'vue';
import axios from 'axios';
import { WEBSITE_URL } from '@/constant/constants';
import { user } from '@/service/api';
import { login as handleAuth } from '@/helper/handle-auth';
import { detectIE } from '@/helper/detect-browser';
import { getLinkHelper } from '@/helper/functions';
import errorMessage from '@/components/form/error-message.vue';
import { AuthResponse } from '@/model/auth';
import { USER_ROLE_PCU_USER } from '@/constant/auth-constants';
import apiBackend from '@/service/api-insurance';


export default Vue.extend({
  name: 'login',
  components: {
    errorMessage,
  },
  props: ['LoggedIn'],
  data: () => ({
    username: '',
    password: '',
    showError: false,
  }),
  methods: {
    // eslint-disable-next-line no-shadow
    isPCUser(user: AuthResponse) {
      const index = user.roles[0].indexOf(USER_ROLE_PCU_USER);
      return index > -1 as boolean;
    },
    onSubmit() {
      if (!this.isIE) {
        const data = {
          email: this.username,
          password: this.password,
        };
        user.login(data).then((response) => {
          const loginUser = handleAuth(response.data.data);
          this.$store.commit('authModule/setUser', loginUser);

          this.$emit('setIsLoggedIn', true);

          this.$store.commit('sidModule/setSID', null);
          this.$store.commit('sidModule/setInsuranceId', null);
          if (this.isPCUser(response.data.data)) {
            apiBackend.deletePCUUserInsurances(response.data.data.id);
          }
          this.$router.push('/products');
        });
      } else {
        this.showError = true;
      }
    },
    apiLogin(token: string, page: string) {
      const data = {
        keyn: token,
      };
      user.loginAPI(data).then((response) => {
        const loginUser = handleAuth(response.data.UserTokenResource);
        this.$store.commit('authModule/setUser', loginUser);
        this.$emit('setIsLoggedIn', true);

        if (response.data.insuranceId) {
          this.$store.commit('sidModule/setSID', response.data.sid);
          this.$store.dispatch('sidModule/fetchSIDFromAPI', response.data.sid);
          const path = `get-insurance/${response.data.insuranceId}`;
          this.$router.push({
            path,
          }).catch((err) => {
            throw new Error(`Problem handling something: ${err}.`);
          });
        } else if (page === '') {
          this.$store.dispatch('sidModule/clearSidData');
          this.$router.push(getLinkHelper(response.data.insuranceType));
        } else {
          this.$store.dispatch('sidModule/clearSidData');
          this.$router.push(page);
        }
      });
    },
    onHaveGetToken(token: string, page: string) {
      const APIUrl = `${WEBSITE_URL}ams-login-data`;
      axios.get(APIUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const loginUser = handleAuth(response.data.data);
        this.$store.commit('authModule/setUser', loginUser);

        this.$emit('setIsLoggedIn', true);
        if (response.data.id) {
          this.redirectToRoute(response.data.id);
        } else {
          this.$router.push(`/${page}`);
        }
      });
    },
    GetInsurance(token: string) {
      const APIUrl = `${WEBSITE_URL}ams-get-insurance`;
      axios.get(APIUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const loginUser = handleAuth(response.data.data);
        this.$store.commit('authModule/setUser', loginUser);

        this.$emit('setIsLoggedIn', true);
        if (response.data.id) {
          this.redirectToRoute(response.data.id);
        }
        return response.data.id;
      });
      return false;
    },
    redirectToRoute(id: number) {
      const path = `transport-insurance/${id}`;
      this.$router.push({
        path,
      }).catch((err) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
  },
  computed: {
    isIE() {
      return detectIE();
    },
  },
  mounted() {
    /* console.log(`Is IE: ${this.isIE}`); */
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
    // this.$emit('LoggedIn', true);
    if (this.$route.query.keyn) {
      // TO DO go to a specific insurance depending on the parameter page
      // this.apiLogin(this.$route.query.keyn.toString(), '/products');
      this.apiLogin(this.$route.query.keyn.toString(), '');
    }
    if (this.$route.query.keym) {
      this.apiLogin(this.$route.query.keyn.toString(), '/offers');
    }
  },
});
