import { render, staticRenderFns } from "./montage-chart.vue?vue&type=template&id=2fae83c8&scoped=true&"
import script from "./montage-chart.vue?vue&type=script&lang=ts&"
export * from "./montage-chart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fae83c8",
  null
  
)

export default component.exports