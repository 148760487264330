




































import Vue from 'vue';
import { user } from '@/service/api';

export default Vue.extend({
  data() {
    return {
      check: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      email: '',
    };
  },
  mounted() {
    this.check = true;
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  },
  methods: {
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    onSubmit() {
      try {
        const data = {
          email: this.email,
        };
        user.sendResetLinkEmail(data).then((response) => {
          this.openModal(
            '',
            'Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet',
          );
        });
      } catch (error) {
        this.openModal(
          '',
          'Es gab einen Fehler beim Zurücksetzen des Passworts.',
        );
      }
    },
  },
});
