import { getTabsHelper, copyObject } from '@/helper/functions';

const tabsModule = {
  namespaced: true,
  state: {
    menuParams: null,
    tabsItems: null,
  },
  getters: {
    getTabsHelper: (state) => state.tabsItems,
    getPrevTab: (state) => (groupKey, tabKey) => {
      let prevTab = null;

      Object.keys(state.tabsItems[groupKey]).forEach((key) => {
        if (+key < +tabKey) {
          prevTab = {
            ...state.tabsItems[groupKey][key],
            key,
          };
        }
      });

      return prevTab;
    },
    getLastTab: (state) => (groupKey) => {
      const item = state.tabsItems[groupKey];

      return {
        ...item[Object.keys(item)[Object.keys(item).length - 1]],
        key: Object.keys(item)[Object.keys(item).length - 1],
      };
    },
  },
  mutations: {
    setCustomTabsItems: (state, { groupKey, elements }) => {
      const tbs = copyObject(state.tabsItems);

      tbs[groupKey] = elements;

      state.tabsItems = tbs;
    },
    setTabsItemsByInsuranceKey: (state, key: string) => {
      state.tabsItems = getTabsHelper(key, state.menuParams);
    },
    setMenuParams: (state, params) => {
      state.menuParams = params;
    },
  }
};

export default tabsModule;
