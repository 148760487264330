


































































































































































































































































































































































































































































































































import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import DatePicker from '@/components/form/date-picker.vue';
import { dateService } from '@/service/date-service';
import { getStatisticData } from '@/helper/functions';

type dateFilterParamsType = {
  from: string;
  to: string;
}

export default Vue.extend({
  name: 'MontageChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Object,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      byZuersStatisticValues: [] as number[],
      byZuersStatisticLabels: [] as string[],
      byZuersStatisticTotal: 0,
      byZuersFileName: 'Zürs-Zone',
      statisticLoaded: false,
      zuersExcelData: [] as object[],

      byAbroadStatisticValues: [] as number[],
      byAbroadStatisticLabels: [] as string[],
      byAbroadStatisticTotal: 0,
      byAbroadFileName: 'Ausland',
      abroadExcelData: [] as object[],

      byAbroad2StatisticValues: [] as number[],
      byAbroad2StatisticLabels: [] as string[],
      byAbroad2StatisticTotal: 0,
      byAbroad2FileName: 'Ausland 2',
      abroad2ExcelData: [] as object[],

      byContractTypeStatisticValues: [] as number[],
      byContractTypeStatisticLabels: [] as string[],
      byContractTypeStatisticTotal: 0,
      byContractTypeFileName: 'Vertragsart',
      contractExcelData: [] as object[],

      byPolicyHolderStatisticValues: [] as number[],
      byPolicyHolderStatisticLabels: [] as string[],
      byPolicyHolderStatisticTotal: 0,
      byPolicyHolderFileName: 'Versicherungsnehmer',
      policyHolderExcelData: [] as object[],

      byTypeOfAssemblyStatisticValues: [] as number[],
      byTypeOfAssemblyStatisticLabels: [] as string[],
      byTypeOfAssemblyStatisticTotal: 0,
      byTypeOfAssemblyFileName: 'Art der Industriemontage',
      typeOfAssemblyExcelData: [] as object[],

      byTypeOfEnergyAssemblyStatisticValues: [] as number[],
      byTypeOfEnergyAssemblyStatisticLabels: [] as string[],
      byTypeOfEnergyAssemblyStatisticTotal: 0,
      byTypeOfEnergyAssemblyFileName: 'Art der Energie-Montage',
      typeOfEnergyAssemblyExcelData: [] as object[],

      byTk7106StatisticValues: [] as number[],
      byTk7106StatisticLabels: [] as string[],
      byTk7106StatisticTotal: 0,
      byTk7106FileName: 'Gebrauchte Sachen als Montageobjekt (TK 7106)',
      tk7106ExcelData: [] as object[],

      byDangerLevelStatisticValues: [] as number[],
      byDangerLevelStatisticLabels: [] as string[],
      byDangerLevelStatisticTotal: 0,
      byDangerLevelFileName: 'Gefahrengrad',
      dangerLevelExcelData: [] as object[],

      avgDurationOfAssembly: 0,
      avgDurationOfAssemblyLoaded: false,
      avgDurationOfTrial: 0,
      avgDurationOfTrialLoaded: false,

      selectFilterType: 'all',
      from: '',
      to: '',
    };
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectFilterType = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    exportExcelData(): object {
      return {
        zuers: this.zuersExcelData,
        aboard: this.abroadExcelData,
        abroad2: this.abroad2ExcelData,
        contract: this.contractExcelData,
        policyHolder: this.policyHolderExcelData,
        typeOfAssembly: this.typeOfAssemblyExcelData,
        typeOfEnergyAssembly: this.typeOfEnergyAssemblyExcelData,
        tk7106: this.tk7106ExcelData,
        dangerLevel: this.dangerLevelExcelData,
        avgDurationOfTrial: this.avgDurationOfTrial,
        avgDurationOfAssembly: this.avgDurationOfAssembly,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  watch: {
    loadDataDates() {
      this.getStatistic();
    },
    statisticLoaded(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    getStatistic() {
      const filterParams = this.prepareDateFilterParams();

      apiBackend.montageStatistic(filterParams.from, filterParams.to).then((response: any) => {
        this.importStatisticData(response.data);
      });
    },
    importStatisticData(data: any) {
      const groupKey = 'montage_type';

      const zuersData = getStatisticData(data.zuers, groupKey);

      this.byZuersStatisticValues = zuersData.values;
      this.byZuersStatisticLabels = zuersData.labels;
      this.byZuersStatisticTotal = zuersData.statisticTotal;
      this.zuersExcelData = zuersData.excelData;

      const abroadData = getStatisticData(data.abroad, groupKey);

      this.byAbroadStatisticValues = abroadData.values;
      this.byAbroadStatisticLabels = abroadData.labels;
      this.byAbroadStatisticTotal = abroadData.statisticTotal;
      this.abroadExcelData = abroadData.excelData;

      const abroad2Data = getStatisticData(data.abroad2, groupKey);

      this.byAbroad2StatisticValues = abroad2Data.values;
      this.byAbroad2StatisticLabels = abroad2Data.labels;
      this.byAbroad2StatisticTotal = abroad2Data.statisticTotal;
      this.abroad2ExcelData = abroad2Data.excelData;

      const contractData = getStatisticData(data.contract, groupKey);

      this.byContractTypeStatisticValues = contractData.values;
      this.byContractTypeStatisticLabels = contractData.labels;
      this.byContractTypeStatisticTotal = contractData.statisticTotal;
      this.contractExcelData = contractData.excelData;

      const policyholderData = getStatisticData(data.policyholder, groupKey);

      this.byPolicyHolderStatisticValues = policyholderData.values;
      this.byPolicyHolderStatisticLabels = policyholderData.labels;
      this.byPolicyHolderStatisticTotal = policyholderData.statisticTotal;
      this.policyHolderExcelData = policyholderData.excelData;

      const typeOfAssemblyData = getStatisticData(data.typeOfAssembly, groupKey);

      this.byTypeOfAssemblyStatisticValues = typeOfAssemblyData.values;
      this.byTypeOfAssemblyStatisticLabels = typeOfAssemblyData.labels;
      this.byTypeOfAssemblyStatisticTotal = typeOfAssemblyData.statisticTotal;
      this.typeOfAssemblyExcelData = typeOfAssemblyData.excelData;

      const typeOfEnergyAssemblyData = getStatisticData(data.typeOfEnergyAssembly, groupKey);

      this.byTypeOfEnergyAssemblyStatisticValues = typeOfEnergyAssemblyData.values;
      this.byTypeOfEnergyAssemblyStatisticLabels = typeOfEnergyAssemblyData.labels;
      this.byTypeOfEnergyAssemblyStatisticTotal = typeOfEnergyAssemblyData.statisticTotal;
      this.typeOfEnergyAssemblyExcelData = typeOfEnergyAssemblyData.excelData;

      const tk7106Data = getStatisticData(data.tk7106, groupKey);

      this.byTk7106StatisticValues = tk7106Data.values;
      this.byTk7106StatisticLabels = tk7106Data.labels;
      this.byTk7106StatisticTotal = tk7106Data.statisticTotal;
      this.tk7106ExcelData = tk7106Data.excelData;

      const dangerLevelData = getStatisticData(data.dangerLevel, groupKey);

      this.byDangerLevelStatisticValues = dangerLevelData.values;
      this.byDangerLevelStatisticLabels = dangerLevelData.labels;
      this.byDangerLevelStatisticTotal = dangerLevelData.statisticTotal;
      this.dangerLevelExcelData = dangerLevelData.excelData;

      this.avgDurationOfAssembly = +(+data.durationOfAssembly).toFixed(2);
      this.avgDurationOfTrial = +(+data.durationOfTrial).toFixed(2);

      this.statisticLoaded = true;
    },
    prepareDateFilterParams(): dateFilterParamsType {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      return {
        from,
        to,
      };
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectFilterType);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectFilterType][key]) {
        this.selectFilterType = this.selectOptions[0].value;
      }
    },
  },
});
