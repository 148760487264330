import LOGO from '@/assets/images/logo.png';
import PHONE_LOGO from '@/assets/images/phone_logo.png';
import MAIL_LOGO from '@/assets/images/mail_logo.png';
import TRANSPORT from '@/assets/images/transport-versicherung.jpg';
import BOAT from '@/assets/images/calc_boat.jpg';
import SECURE from '@/assets/images/calc_secur.jpg';
import SHOWS from '@/assets/images/calc_shows.jpg';
import WORK from '@/assets/images/calc_works.jpg';
import HOME_BANNER from '@/assets/images/banner2.jpg';
import OFFERS from '@/assets/images/offers.png';
import DOCUMENTS from '@/assets/images/documents.png';
import CARS_PHOTO from '@/assets/images/ausstellungsversicherung.jpg';
import GLASS_PHOTO from '@/assets/images/musterkollektions-versicherung.jpg';
import LOGISTIC from '@/assets/images/calc_logistic.jpg';
import ELEKTRONIK from '@/assets/images/elektronik.jpg';
import WINDENERGIEANLAGEN from '@/assets/images/windenergieanlagen.jpeg';
import BIOGAS from '@/assets/images/biogas.jpg';
import ASSEMBLY from '@/assets/images/montageversicherung.jpg';
import LOGO_JPG from '@/assets/images/logo.jpg';
import PHOTOVOLTAIK_LOGO from '@/assets/images/photovoltaik.jpeg';

export {
  LOGO,
  PHONE_LOGO,
  MAIL_LOGO,
  HOME_BANNER,
  OFFERS,
  DOCUMENTS,
  TRANSPORT,
  BOAT,
  SECURE,
  WORK,
  SHOWS,
  CARS_PHOTO,
  GLASS_PHOTO,
  LOGISTIC,
  ELEKTRONIK,
  WINDENERGIEANLAGEN,
  BIOGAS,
  ASSEMBLY,
  LOGO_JPG,
  PHOTOVOLTAIK_LOGO,
};

export default null;
