





















import Vue from 'vue';
import { CASE_STATUS } from '@/constant/constants';
// eslint-disable-next-line import/no-cycle
import { getTabGroupIdNumberHelper } from '@/helper/functions';
import InsuranceTab from '@/model/tabs';

type tabs = {
  insuranceForms: any;
  priceForms: any;
};

export default Vue.extend({
  name: 'tabs',
  props: ['caseStatus', 'insuranceKey', 'activeTab'],
  watch: {
    insuranceKey: {
      immediate: true,
      handler(value) {
        this.setTabsFromStoreByKey(value);

        this.tabsItems = this.getTabsFromStore();
      },
    },
    activeTab: {
      immediate: true,
      handler(value) {
        if (this.insuranceKey) {
          this.changeMaxGroupIndex(value);
          this.routePushActiveTab();
        }
      },
    },
    tabsFromStore: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.tabsItems = value;
        }
      },
    },
  },
  data() {
    return {
      tabsItems: {
        insuranceForms: {
        },
        priceForms: [] as any,
      } as tabs,
      maxGroupIndex: 1,
    };
  },
  computed: {
    tabsFromStore(): tabs {
      return this.getTabsFromStore();
    },
  },
  methods: {
    getTabsFromStore(): tabs {
      return this.$store.getters['tabsModule/getTabsHelper'];
    },
    setTabsFromStoreByKey(key: string) {
      return this.$store.commit('tabsModule/setTabsItemsByInsuranceKey', key);
    },
    changeMaxGroupIndex(activeTab) {
      const activeGroupIndex = getTabGroupIdNumberHelper(activeTab.groupId);
      if (this.maxGroupIndex < activeGroupIndex) {
        this.maxGroupIndex = activeGroupIndex;
      }
    },
    changeActiveTab(groupId, tabId) {
      const localActiveTab: InsuranceTab = {
        groupId,
        tabId,
        name: this.tabsItems[groupId][tabId].name,
        path: this.tabsItems[groupId][tabId].path,
      };
      this.$emit('clicked', localActiveTab);
    },
    onTabClick(groupId, tabId) {
      const groupIndex = getTabGroupIdNumberHelper(groupId);
      if (this.caseStatus === CASE_STATUS.SUGGESTION || this.caseStatus === CASE_STATUS.CONTRACT || groupIndex < this.maxGroupIndex || (groupIndex === this.maxGroupIndex && tabId <= this.activeTab.tabId) || groupId === 'files'
      ) {
        this.changeActiveTab(groupId, tabId);
      } else {
        console.log('blockTabClick');
      }
    },
    routePushActiveTab() {
      if (this.activeTab.path !== '' && this.$route.name !== this.activeTab.path) {
        this.$router.push({
          name: this.activeTab.path,
        }).catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.changeMaxGroupIndex(this.activeTab);
    }, 500);
  },
  beforeUpdate() {
    this.tabsItems = this.tabsFromStore;
  },
});
