





































































































































































































import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import DatePicker from '@/components/form/date-picker.vue';
import { dateService } from '@/service/date-service';
import { getStatisticData } from '@/helper/functions';

type zuersStatisticItemType = {
  zuers: string;
  insurance_count: number;
}

type dateFilterParamsType = {
  from: string;
  to: string;
}

export default Vue.extend({
  name: 'MachineChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Object,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      machineTypeStatisticValues: [] as number[],
      machineTypeStatisticLabels: [] as string[],
      machineTypeStatisticTotal: 0,
      machineTypeFileName: 'Maschine vertragsart',
      statisticLoaded: false,
      machineTypeExcelData: [] as object[],

      paymentMethodDataStatisticValues: [] as number[],
      paymentMethodDataStatisticLabels: [] as string[],
      paymentMethodStatisticTotal: 0,
      paymentMethodFileName: 'Zahlweise',
      paymentMethodExcelData: [] as object[],

      zuersStatisticValues: [] as number[],
      zuersStatisticLabels: [] as string[],
      zuersStatisticTotal: 0,
      zuersFileName: 'Zuers',
      zuersExcelData: [] as object[],

      selectFilterType: 'all',
      from: '',
      to: '',
    };
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectFilterType = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    exportExcelData(): object {
      return {
        machineType: this.machineTypeExcelData,
        paymentMethod: this.paymentMethodExcelData,
        zuers: this.zuersExcelData,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  watch: {
    loadDataDates() {
      this.getStatisticData();
    },
    statisticLoaded(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    prepareDateFilterParams(): dateFilterParamsType {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      return {
        from,
        to,
      };
    },
    getStatisticData() {
      const filterParams = this.prepareDateFilterParams();

      apiBackend.individualStatistic(filterParams.from, filterParams.to).then((response: any) => {
        this.importStatisticData(response.data);
      });
    },
    importStatisticData(data: any) {
      const typeData = getStatisticData(data.type, 'machine_type');

      this.machineTypeStatisticValues = typeData.values;
      this.machineTypeStatisticLabels = typeData.labels;
      this.machineTypeStatisticTotal = typeData.statisticTotal;
      this.machineTypeExcelData = typeData.excelData;

      const paymentMethodData = getStatisticData(data.paymentMethod, 'payment_method');

      this.paymentMethodDataStatisticValues = paymentMethodData.values;
      this.paymentMethodDataStatisticLabels = paymentMethodData.labels;
      this.paymentMethodStatisticTotal = paymentMethodData.statisticTotal;
      this.paymentMethodExcelData = paymentMethodData.excelData;

      const zuersData = getStatisticData(data.zuers, 'payment_method');

      this.zuersStatisticValues = zuersData.values;
      this.zuersStatisticLabels = zuersData.labels;
      this.zuersStatisticTotal = zuersData.statisticTotal;
      this.zuersExcelData = zuersData.excelData;

      this.statisticLoaded = true;
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectFilterType);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectFilterType][key]) {
        this.selectFilterType = this.selectOptions[0].value;
      }
    },
  },
});
