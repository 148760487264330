


















































































































































































































































































































































import Vue from 'vue';
import DatePicker from '@/components/form/date-picker.vue';
import { dateService } from '@/service/date-service';
import apiBackend from '@/service/api-insurance';
import { getStatisticData } from '@/helper/functions';

type dateFilterParamsType = {
  from: string;
  to: string;
}

export default Vue.extend({
  name: 'BiogasChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Object,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      byZuersStatisticValues: [] as number[],
      byZuersStatisticLabels: [] as string[],
      byZuersStatisticTotal: 0,
      byZuersFileName: 'Zürs-Zone',
      loadedStatistic: false,
      byZuersStatisticExcelData: [] as object[],

      byNumberOfSystemStatisticValues: [] as number[],
      byNumberOfSystemStatisticLabels: [] as string[],
      byNumberOfSystemStatisticTotal: 0,
      byNumberOfSystemFileName: 'Anzahl der Anlagen',
      byNumberOfSystemExcelData: [] as object[],

      byTypeOfFacilityStatisticValues: [] as number[],
      byTypeOfFacilityStatisticLabels: [] as string[],
      byTypeOfFacilityStatisticTotal: 0,
      byTypeOfFacilityFileName: 'Art der Anlage',
      byTypeOfFacilityExcelData: [] as object[],

      byPaymentMethodStatisticValues: [] as number[],
      byPaymentMethodStatisticLabels: [] as string[],
      byPaymentMethodStatisticTotal: 0,
      byPaymentMethodFileName: 'Zahlungsweise',
      byPaymentMethodExcelData: [] as object[],

      byNetizationStatisticValues: [] as number[],
      byNetizationStatisticLabels: [] as string[],
      byNetizationStatisticTotal: 0,
      byNetizationFileName: 'Nettoisierung',
      byNetizationExcelData: [] as object[],

      byCoverageExtensionsStatisticValues: [] as number[],
      byCoverageExtensionsStatisticLabels: [] as string[],
      byCoverageExtensionsStatisticTotal: 0,
      byCoverageExtensionsFileName: 'Mitversicherung Innere Unruhen',
      byCoverageExtensionsExcelData: [] as object[],

      selectFilterType: 'all',
      from: '',
      to: '',
    };
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    exportExcelData(): object {
      return {
        zuers: this.byZuersStatisticExcelData,
        numberOfSystem: this.byNumberOfSystemExcelData,
        typeOfFacility: this.byTypeOfFacilityExcelData,
        paymentMethod: this.byPaymentMethodExcelData,
        netization: this.byNetizationExcelData,
        coverageExtensions: this.byCoverageExtensionsExcelData,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectFilterType = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  watch: {
    loadDataDates() {
      this.getBiogasStatistic();
    },
    loadedStatistic(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    getBiogasStatistic() {
      const filterParams = this.prepareDateFilterParams();

      apiBackend.biogasStatistic(filterParams.from, filterParams.to).then((response: any) => {
        this.importStatisticData(response.data);
      });
    },
    importStatisticData(data: any) {
      const groupKey = 'biogas_type';

      const zuersData = getStatisticData(data.zuers, groupKey);

      this.byZuersStatisticValues = zuersData.values;
      this.byZuersStatisticLabels = zuersData.labels;
      this.byZuersStatisticTotal = zuersData.statisticTotal;
      this.byZuersStatisticExcelData = zuersData.excelData;

      const numberOfSystemData = getStatisticData(data.numberOfSystem, groupKey);

      this.byNumberOfSystemStatisticValues = numberOfSystemData.values;
      this.byNumberOfSystemStatisticLabels = numberOfSystemData.labels;
      this.byNumberOfSystemStatisticTotal = numberOfSystemData.statisticTotal;
      this.byNumberOfSystemExcelData = numberOfSystemData.excelData;

      const typeOfFacilityData = getStatisticData(data.typeOfFacility, groupKey);

      this.byTypeOfFacilityStatisticValues = typeOfFacilityData.values;
      this.byTypeOfFacilityStatisticLabels = typeOfFacilityData.labels;
      this.byTypeOfFacilityStatisticTotal = typeOfFacilityData.statisticTotal;
      this.byTypeOfFacilityExcelData = typeOfFacilityData.excelData;

      const paymentMethodData = getStatisticData(data.paymentMethod, groupKey);

      this.byPaymentMethodStatisticValues = paymentMethodData.values;
      this.byPaymentMethodStatisticLabels = paymentMethodData.labels;
      this.byPaymentMethodStatisticTotal = paymentMethodData.statisticTotal;
      this.byPaymentMethodExcelData = paymentMethodData.excelData;

      const netizationData = getStatisticData(data.netization, groupKey);

      this.byNetizationStatisticValues = netizationData.values;
      this.byNetizationStatisticLabels = netizationData.labels;
      this.byNetizationStatisticTotal = netizationData.statisticTotal;
      this.byNetizationExcelData = netizationData.excelData;

      const coverageExtensionsData = getStatisticData(data.coverageExtensions, groupKey);

      this.byCoverageExtensionsStatisticValues = coverageExtensionsData.values;
      this.byCoverageExtensionsStatisticLabels = coverageExtensionsData.labels;
      this.byCoverageExtensionsStatisticTotal = coverageExtensionsData.statisticTotal;
      this.byCoverageExtensionsExcelData = coverageExtensionsData.excelData;

      this.loadedStatistic = true;
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectFilterType);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectFilterType][key]) {
        this.selectFilterType = this.selectOptions[0].value;
      }
    },
    prepareDateFilterParams(): dateFilterParamsType {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      return {
        from,
        to,
      };
    },
  },
});
