












import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import AppHeader from './components/shared/app-header.vue';
import AppFooter from './components/shared/app-footer.vue';

export default Vue.extend({
  name: 'MainApp',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      insuranceData: null,
      customerData: null,
      customerDetails: null,
      priceCardData: null,
      caseStatus: 0,
      key: '',
      ID: '',
      isLoggedIn: false,
    };
  },
  methods: {
    getDraftInsurance() {
      apiBackend.draftInsurance().then((response) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { form_data, price_data, insurance_type, status } = response.data.data;

        // eslint-disable-next-line @typescript-eslint/camelcase
        const { insuranceData, customerData } = form_data;

        this.insuranceData = insuranceData;
        this.customerData = customerData ? customerData.user : null;
        this.customerDetails = customerData ? customerData.general : null;
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.priceCardData = price_data;
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.key = insurance_type;
        this.caseStatus = status;
      });
    },
    onID(id: string) {
      this.ID = id;
    },
    getCustomerData(customerData: any) {
      this.customerData = customerData;
    },
    LoggedIn(status: boolean) {
      this.isLoggedIn = status;
    },
  },
  mounted() {
    // console.log(this.$route.params);
  },
});
