<template>
  <div class="container static index">
    <div class="static login">
      <div class="row form-login d-block">
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <h5>Create User</h5>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              name="email"
              id="email"
              placeholder="Email"
              v-model="email"
              required
            />
          </div>
          <div class="form-group">
            <label for="first_name">First Name</label>
            <input
              type="text"
              class="form-control"
              name="first_name"
              id="first_name"
              placeholder="First Name"
              v-model="firstName"
              required
            />
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="form-control"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              v-model="lastName"
              required
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="input-group">
              <input
                v-model="password"
                type="password"
                class="form-control"
                name="password"
                id="password"
                placeholder="Password"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="register-password-confirmation">Confirm Password</label>
            <input
              v-model="passwordConfirmation"
              type="password"
              class="form-control"
              name="register-password-confirmation"
              id="register-password-confirmation"
              placeholder="Confirm Password"
            />
          </div>
          <button type="submit" class="btn btn-primary">Create User</button>
        </form>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { user } from '@/service/api';
import { login as handleAuth } from '@/helper/handle-auth';

export default {
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    };
  },
  methods: {
    async fetchGroups() {
      try {
        const response = await axios.get('https://api.yourapp.com/groups');
        this.groups = response.data;
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    },
    async onSubmit() {
      try {
        const data = {
          email: this.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: this.firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: this.lastName,
          password: this.password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          password_confirmation: this.passwordConfirmation,
        };
        user.registration(data).then((response) => {
          const loginUser = handleAuth(response.data.data);
          this.$store.commit('authModule/setUser', loginUser);

          this.$emit('setIsLoggedIn', true);

          this.$store.commit('sidModule/setSID', null);
          this.$store.commit('sidModule/setInsuranceId', null);
          this.$router.push('/products');
        });
      } catch (error) {
        console.error('Failed to create user:', error);
      }
    },
  },
  mounted() {
    this.fetchGroups();
  },
};
</script>

<style scoped>
.registration-form {
  max-width: 600px;
  margin: 0 auto;
}
.input-group-append {
  margin-left: -1px;
}
</style>
