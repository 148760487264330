import IWarenHandwerker from '@/model/IWarenHandwerker';
import {
  WEBSITE_URL,
  WEBSITE_PUBLIC_URL,
  zipApiUid,
  zipApiKey
} from '@/constant/constants';
// eslint-disable-next-line import/no-cycle
import { api, downloadApi } from './instance';

export type ICalculate =
  | IWarenHandwerker;

const apiBackend = {
  fetch: (
    draw: number,
    search: string,
    length: number,
    start: number,
    letter: string,
    sortBy: string,
    sortType: string,
    idSearchValue: string,
    processNameSearchValue: string,
    userSearchValue: string,
    offerNumber: string,
    customerName: string,
  ) => api.get(`insurance?draw=${draw}&search=${search}&idSearchValue=${idSearchValue}&processNameSearchValue=${processNameSearchValue}&userSearchValue=${userSearchValue}&length=${length}&start=${start}&letter=${letter}&sortBy=${sortBy}&sortType=${sortType}&offerNumber=${offerNumber}&customerName=${customerName}`),
  // calculate: (data: ICalculate, productId: string) => {
  //   const id = productId;
  //   return api.post(`/Calculation/CalculateObject?productId=${id}`, data, {
  //     baseURL: `${RECHENKERN_URL}/api`,
  //     headers: {
  //       common: {
  //         password: 'T1RtRjNXcHV6VWZiXjQjRW9CS3k=',
  //         'Content-Type': 'application/json',
  //       },
  //     },
  //   });
  // },
  calculate: (data: any) =>
    api.post('getPrice', data, {
      baseURL: WEBSITE_URL,
    }),
  getPriceRequest: (data: any) =>
    api.post('getPriceRequest', data, {
      baseURL: WEBSITE_URL,
    }),
  fetchSingle: (id: string) => api.get(`insurance/${id}`),
  insurance: (data: any) =>
    api.post('insurance', data, {
      baseURL: WEBSITE_URL,
    }),
  draftInsurance: () =>
    api.get('insurance/draft', {
      baseURL: WEBSITE_URL,
    }),
  deletePCUInsurance: (id: string) => api.get(`insurance/deletePCUInsurance/${id}`),
  deletePCUUserInsurances: (uuid: string) => api.get(`user/deletePCUUserInsurances/${uuid}`),
  getPrice: (id: number, variantPrice: string) => api.get(`getPrice/${id}/${variantPrice}`),
  getPriceRequestGet: (id: number, variantPrice: string) => api.get(`getPriceRequest/${id}/${variantPrice}`),
  checkSignature: (id: any) => api.get(`insurance/${id}/check-signature`),
  sendEmail: (data: { email: string; price: string}) => api.post('send-email', data),
  sendProposalEmail: (data: { email: string; id: string; pdf: string; btn: string; status: number }) => api.post('send-proposal-email', data),
  // sendEmailToAdmin: (data: { id: string }) => api.post('send-email-to-admin', data),
  manuallyPriceUpdate: (data: { id: number; manuallyPrice: object; status: number}) => api.post('manually-price', data),
  secondProductManuallyPriceUpdate: (data: { id: number; manuallyPrice: object; status: number}) => api.post('second-product-manually-price', data),
  sigature: (id: string, data: { image: string }) => api.post(`insurance/${id}/signature`, data),
  contractNoSignature: (id: string, data: { image: string }) => api.post(`insurance/${id}/signature`, data),
  emailDigital: (data: { email: string; link: string; id: string }) => api.post('email/digital-sign', data),
  // generatePDF: (id: any) => downloadApi.get(`generate-pdf/${id}`),
  generatePDF: (id: any) => api.get(`generate-pdf/${id}`),
  savePDF: (data: any) => api.post('save-pdf', data),
  saveRiskPDF: (data: any) => api.post('save-risk-pdf', data),
  saveTarifPDF: (data: any) => api.post('save-tarif-pdf', data),
  saveBasicPDF: (data: any) => api.post('save-grunddaten-pdf', data),
  connectToOldSystem: () => api.get('login-api-old-logistik-system'),
  generatePDFforUser: (id: any) => api.get(`generate-pdf-for-user/${id}`),
  getStatusInsurance: (id: any) => api.get(`get-status-insurance/${id}`),
  // exportToExcel: () => api.get('export_insurance'),
  exportToExcel: () => downloadApi.get('export_insurance'),
  getProducts: () => api.get('getProducts'),
  getPermissionProduct: (key: any) => api.get(`getPermissionProduct/${key}`),
  getInsuranceFormsApiData: () => api.get('get-insurance-forms-api-data'),
  getInsuranceFromSID: (sid: any) => api.get(`get-insurance-from-sid/${sid}`),
  sendShortXML: (id: number) => api.get(`send-short-XML/${id}`),
  sendBigXML: (id: number, data = { variant: '' }) => api.post(`${id}/send-xml`, data),
  downloadBigXML: (id: number, data = { variant: '' }) => downloadApi.post(`${id}/download-xml`, data),
  downloadPDF: (id: number) => downloadApi.get(`pdf/${id}/vorschlag`),
  checkZipCode: (zipCode: string) => api.get(`/pickuppoint/api/postalCode/DE/getCityAndType/${zipCode}.json`, {
    baseURL: 'https://api.bring.com',
    headers: {
      common: {
        'X-MyBring-API-Uid': zipApiUid,
        'X-MyBring-API-Key': zipApiKey,
        'X-Bring-Client-URL': WEBSITE_PUBLIC_URL,
      },
    },
  }),
  uploadFile: (url: string, fileName: string, file: any) => {
    const formData = new FormData();

    formData.append(fileName, file);

    return api.post(url, formData, {
      baseURL: WEBSITE_URL,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  productsStatistic: (from: string, to: string) => api.get(`statistic/products?from=${from}&to=${to}`),
  individualStatistic: (from: string, to: string) => api.get(`statistic/individual?from=${from}&to=${to}`),
  buildingStatistic: (from: string, to: string) => api.get(`statistic/building?from=${from}&to=${to}`),
  windStatistic: (from: string, to: string) => api.get(`statistic/wind?from=${from}&to=${to}`),
  biogasStatistic: (from: string, to: string) => api.get(`statistic/biogas?from=${from}&to=${to}`),
  photovoltaicsStatistic: (from: string, to: string) => api.get(`statistic/photovoltaics?from=${from}&to=${to}`),
  montageStatistic: (from: string, to: string) => api.get(`statistic/montage?from=${from}&to=${to}`),
  allStatistic: (from: string, to: string) => api.get(`statistic/all?from=${from}&to=${to}`),

  downloadInsurancePlaces: () => downloadApi.get('download-template/places-excel'),
  exportPlacesData: (data: any[]) => downloadApi.post('export-data/places-excel', {
    data
  }),
  downloadPositionsData: (productKey: string|null = null) => downloadApi.get(`download-template/position-data-excel${productKey ? `/${productKey}` : ''}`),
  exportStatisticData: (data: object) => downloadApi.post('export-statistic-data', {
    ...data
  }),
};

export default apiBackend;
