// eslint-disable-next-line import/no-cycle
import apiBackend from '@/service/api-insurance';

const sidModule = {
  namespaced: true,
  state: {
    SID: null,
    insuranceId: null
  },
  getters: {
    getSID: (state) => state.SID,
    getInsuranceId: (state) => state.insuranceId
  },
  mutations: {
    setSID(state, SID) {
      state.SID = SID;
      localStorage.setItem('SID', SID);
    },
    setInsuranceId(state, insuranceId) {
      state.insuranceId = insuranceId;
      localStorage.setItem('insuranceId', insuranceId);
    },
    clearSID(state) {
      state.SID = null;
      localStorage.removeItem('SID');
    },
    clearInsuranceId(state) {
      state.insuranceId = null;
      localStorage.removeItem('insuranceId');
    }
  },
  actions: {
    clearSidData({ commit }) {
      commit('clearSID');
      commit('clearInsuranceId');
    },
    async fetchSIDFromAPI({ commit }, sid) {
      try {
        const response = await apiBackend.getInsuranceFromSID(sid);
        commit('setInsuranceId', response.data || null);
      } catch (error) {
        console.error('Failed to fetch SID:', error);
        commit('setInsuranceId', null);
      }
    }
  }
};

export default sidModule;
