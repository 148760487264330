













































































import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import { dateService } from '@/service/date-service';
import DatePicker from '@/components/form/date-picker.vue';
import { productNames } from '@/constant/constants';

type insuranceTypeItem = {
  type: string;
  type_key: string;
  count: number;
}

export default Vue.extend({
  name: 'ProductsChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Array,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      values: [] as number[],
      labels: [] as string[],
      total: 0,
      selectValue: 'all',
      from: '',
      to: '',
      loadedStatistic: false,
      exportStatisticData: [] as object[],
    };
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectValue = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    getFileName(): string {
      return this.selectOptions.find((item) => item.value === this.selectValue)?.text ||
        this.selectOptions[0].text;
    },
    exportExcelData(): object {
      return {
        data: this.exportStatisticData,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  watch: {
    loadDataDates() {
      this.getData();
    },
    loadedStatistic(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    getData() {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      apiBackend.productsStatistic(from, to).then((response: any) => {
        this.importStatisticData(response.data);
      }).catch((e) => {
        console.error(`Error - ${e}`);
      });
    },
    importStatisticData(data: any) {
      const items: insuranceTypeItem[] = data;

      const transformedNameItems = items.map((itm) => ({
        ...itm,
        type: productNames[itm.type_key],
      })).reduce((accumulator, currentValue) => {
        const accumulatorElement = accumulator;

        if (!accumulatorElement[currentValue.type]) {
          accumulatorElement[currentValue.type] = +currentValue.count;
        } else {
          accumulatorElement[currentValue.type] += +currentValue.count;
        }

        return accumulatorElement;
      }, {
      });

      this.labels = [];
      this.values = [];
      this.total = 0;

      Object.keys(transformedNameItems).forEach((key) => {
        this.labels.push(`${key}: ${transformedNameItems[key]}`);
        this.values.push(transformedNameItems[key]);

        this.total += transformedNameItems[key];

        this.exportStatisticData.push({
          name: key,
          value: transformedNameItems[key],
        });
      });

      this.loadedStatistic = true;
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectValue);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectValue][key]) {
        this.selectValue = this.selectOptions[0].value;
      }
    },
  },
});

