import { api } from './instance';

const user = {
  login: (data: object) => api.post('login', data),
  loginAPI: (data: object) => api.post('auth-user/login-from-vue', data),
  registration: (data: object) => api.post('registration', data),
  resetPassword: (data: object) => api.post('password/reset', data),
  sendResetLinkEmail: (data: object) => api.post('password/email', data),
};
const insurance = {};
export { user, insurance };
