






























































































































































































































































































































































































































































































































import Vue from 'vue';
import DatePicker from '@/components/form/date-picker.vue';
import { dateService } from '@/service/date-service';
import apiBackend from '@/service/api-insurance';
import { getStatisticData } from '@/helper/functions';

type dateFilterParamsType = {
  from: string;
  to: string;
}

export default Vue.extend({
  name: 'PhotovoltaicsChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Object,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      byZuersStatisticValues: [] as number[],
      byZuersStatisticLabels: [] as string[],
      byZuersStatisticTotal: 0,
      byZuersFileName: 'Zürs-Zone',
      statisticLoaded: false,
      zuersExcelData: [] as object[],

      byNaturalHazardsClassStatisticValues: [] as number[],
      byNaturalHazardsClassStatisticLabels: [] as string[],
      byNaturalHazardsClassStatisticTotal: 0,
      byNaturalHazardsClassFileName: 'Naturgefahrenklasse',
      naturalHazardsExcelData: [] as object[],

      byNumberOfSystemStatisticValues: [] as number[],
      byNumberOfSystemStatisticLabels: [] as string[],
      byNumberOfSystemStatisticTotal: 0,
      byNumberOfSystemFileName: 'Art der Anlage',
      numberOfSystemExcelData: [] as object[],

      byCoinsuranceSoftwareStatisticValues: [] as number[],
      byCoinsuranceSoftwareStatisticLabels: [] as string[],
      byCoinsuranceSoftwareStatisticTotal: 0,
      byCoinsuranceSoftwareFileName: 'Mitversicherung Softwareversicherung/Datenversicherung',
      coinsuranceSoftwareExcelData: [] as object[],

      ByFireAreaStatisticValues: [] as number[],
      ByFireAreaStatisticLabels: [] as string[],
      ByFireAreaSoftwareStatisticTotal: 0,
      ByFireAreaFileName: 'Auslagerung in einen anderen Gefahren/Feuerbereich',
      fireAreaExcelData: [] as object[],

      byStorageOfDataValues: [] as number[],
      byStorageOfDataLabels: [] as string[],
      byStorageOfDataStatisticTotal: 0,
      byStorageOfDataFileName: 'Lagerung der Daten in Datensicherheitsschrank',
      storageOfDataExcelData: [] as object[],

      byAvgRisk: 0,

      byPreviousLossHistoryValues: [] as number[],
      byPreviousLossHistoryLabels: [] as string[],
      byPreviousLossHistoryStatisticTotal: 0,
      byPreviousLossHistoryFileName: 'Vorschadenhistorie',
      previousLossHistoryExcelData: [] as object[],

      byFourEyesPrincipeValues: [] as number[],
      byFourEyesPrincipeLabels: [] as string[],
      byFourEyesPrincipeStatisticTotal: 0,
      byFourEyesPrincipeFileName: 'Vier-Augen-Prinzip',
      fourEyesPrincipeExcelData: [] as object[],

      byInputLogicValues: [] as number[],
      byInputLogicLabels: [] as string[],
      byInputLogicStatisticTotal: 0,
      byInputLogicFileName: 'Eingabelogik',
      inputLogicExcelData: [] as object[],

      selectFilterType: 'all',
      from: '',
      to: '',
    };
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    exportExcelData(): object {
      return {
        zuers: this.zuersExcelData,
        naturalHazards: this.naturalHazardsExcelData,
        numberOfSystem: this.numberOfSystemExcelData,
        coinsuranceSoftware: this.coinsuranceSoftwareExcelData,
        fireArea: this.fireAreaExcelData,
        storageOfData: this.storageOfDataExcelData,
        avgRisk: this.byAvgRisk,
        previousLossHistory: this.previousLossHistoryExcelData,
        fourEyesPrincipe: this.fourEyesPrincipeExcelData,
        inputLogic: this.inputLogicExcelData,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectFilterType = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  watch: {
    loadDataDates() {
      this.getStatistic();
    },
    statisticLoaded(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    getStatistic() {
      const filterParams = this.prepareDateFilterParams();

      apiBackend.photovoltaicsStatistic(filterParams.from, filterParams.to).then((response: any) => {
        this.importStatisticData(response.data);
      });
    },
    importStatisticData(data: any) {
      const groupKey = 'photovoltaics_type';

      const zuersData = getStatisticData(data.zuers, groupKey);

      this.byZuersStatisticValues = zuersData.values;
      this.byZuersStatisticLabels = zuersData.labels;
      this.byZuersStatisticTotal = zuersData.statisticTotal;
      this.zuersExcelData = zuersData.excelData;

      const naturalHazardsClassData = getStatisticData(data.naturalHazardsClass, groupKey);

      this.byNaturalHazardsClassStatisticValues = naturalHazardsClassData.values;
      this.byNaturalHazardsClassStatisticLabels = naturalHazardsClassData.labels;
      this.byNaturalHazardsClassStatisticTotal = naturalHazardsClassData.statisticTotal;
      this.naturalHazardsExcelData = naturalHazardsClassData.excelData;

      const numberOfSystemData = getStatisticData(data.numberOfSystem, groupKey);

      this.byNumberOfSystemStatisticValues = numberOfSystemData.values;
      this.byNumberOfSystemStatisticLabels = numberOfSystemData.labels;
      this.byNumberOfSystemStatisticTotal = numberOfSystemData.statisticTotal;
      this.numberOfSystemExcelData = numberOfSystemData.excelData;

      const coinsuranceSoftwareData = getStatisticData(data.coinsuranceSoftware, groupKey);

      this.byCoinsuranceSoftwareStatisticValues = coinsuranceSoftwareData.values;
      this.byCoinsuranceSoftwareStatisticLabels = coinsuranceSoftwareData.labels;
      this.byCoinsuranceSoftwareStatisticTotal = coinsuranceSoftwareData.statisticTotal;
      this.coinsuranceSoftwareExcelData = coinsuranceSoftwareData.excelData;

      const fireAreaData = getStatisticData(data.fireArea, groupKey);

      this.ByFireAreaStatisticValues = fireAreaData.values;
      this.ByFireAreaStatisticLabels = fireAreaData.labels;
      this.ByFireAreaSoftwareStatisticTotal = fireAreaData.statisticTotal;
      this.fireAreaExcelData = fireAreaData.excelData;

      const storageOfData = getStatisticData(data.storageOfData, groupKey);

      this.byStorageOfDataValues = storageOfData.values;
      this.byStorageOfDataLabels = storageOfData.labels;
      this.byStorageOfDataStatisticTotal = storageOfData.statisticTotal;
      this.storageOfDataExcelData = storageOfData.excelData;

      this.byAvgRisk = +data.avgRisk.toFixed(2);

      const previousLossHistoryData = getStatisticData(data.previousLossHistory, groupKey);

      this.byPreviousLossHistoryValues = previousLossHistoryData.values;
      this.byPreviousLossHistoryLabels = previousLossHistoryData.labels;
      this.byPreviousLossHistoryStatisticTotal = previousLossHistoryData.statisticTotal;
      this.previousLossHistoryExcelData = previousLossHistoryData.excelData;

      const fourEyesPrincipeData = getStatisticData(data.fourEyesPrincipe, groupKey);

      this.byFourEyesPrincipeValues = fourEyesPrincipeData.values;
      this.byFourEyesPrincipeLabels = fourEyesPrincipeData.labels;
      this.byFourEyesPrincipeStatisticTotal = fourEyesPrincipeData.statisticTotal;
      this.fourEyesPrincipeExcelData = fourEyesPrincipeData.excelData;

      const inputLogicData = getStatisticData(data.inputLogic, groupKey);

      this.byInputLogicValues = inputLogicData.values;
      this.byInputLogicLabels = inputLogicData.labels;
      this.byInputLogicStatisticTotal = inputLogicData.statisticTotal;
      this.inputLogicExcelData = inputLogicData.excelData;

      this.statisticLoaded = true;
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectFilterType);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectFilterType][key]) {
        this.selectFilterType = this.selectOptions[0].value;
      }
    },
    prepareDateFilterParams(): dateFilterParamsType {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      return {
        from,
        to,
      };
    },
  },
});
