
















































































































































































































































































































































import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import DatePicker from '@/components/form/date-picker.vue';
import { dateService } from '@/service/date-service';
import { getStatisticData } from '@/helper/functions';

type dateFilterParamsType = {
  from: string;
  to: string;
}

export default Vue.extend({
  name: 'BuildingChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Object,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      buildingContractTypeStatisticValues: [] as number[],
      buildingContractTypeStatisticLabels: [] as string[],
      buildingContractTypeStatisticTotal: 0,
      buildingContractTypeFileName: 'Bauleistung vertragsart',
      statisticLoaded: false,
      buildingContractExcelData: [] as object[],

      buildingZursStatisticValues: [] as number[],
      buildingZursStatisticLabels: [] as string[],
      buildingZursStatisticTotal: 0,
      buildingZursFileName: 'Bauleistung zurs',
      buildingZursExcelData: [] as object[],

      buildingStatisticValues5155: [] as number[],
      buildingStatisticLabels5155: [] as string[],
      buildingStatisticTotal5155: 0,
      buildingFileName5155: 'Altbau nach AZ 5155',
      building5155ExcelData: [] as object[],

      buildingStatisticValues5180: [] as number[],
      buildingStatisticLabels5180: [] as string[],
      buildingStatisticTotal5180: 0,
      buildingFileName5180: 'Altbau nach AZ 5180',
      building5180ExcelData: [] as object[],

      buildingStatisticValues5181: [] as number[],
      buildingStatisticLabels5181: [] as string[],
      buildingStatisticTotal5181: 0,
      buildingFileName5181: 'Altbau nach AZ 5181',
      building5181ExcelData: [] as object[],

      buildingStatisticValuesAltbau: [] as number[],
      buildingStatisticLabelsAltbau: [] as string[],
      buildingStatisticTotalAltbau: 0,
      buildingFileNameAltbau: 'Altbau',
      buildingAltbauExcelData: [] as object[],

      selectFilterType: 'all',
      from: '',
      to: '',
    };
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    exportExcelData(): object {
      return {
        buildingContract: this.buildingContractExcelData,
        buildingZurs: this.buildingZursExcelData,
        building5155: this.building5155ExcelData,
        building5180: this.building5180ExcelData,
        building5181: this.building5181ExcelData,
        buildingAltbau: this.buildingAltbauExcelData,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectFilterType = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  watch: {
    loadDataDates() {
      this.getStatistic();
    },
    statisticLoaded(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    getStatistic() {
      const filterParams = this.prepareDateFilterParams();

      apiBackend.buildingStatistic(filterParams.from, filterParams.to).then((response: any) => {
        this.importStatisticData(response.data);
      });
    },
    importStatisticData(data: any) {
      const groupKey = 'building_type';

      const contractData = getStatisticData(data.contract, 'contract_type');

      this.buildingContractTypeStatisticValues = contractData.values;
      this.buildingContractTypeStatisticLabels = contractData.labels;
      this.buildingContractTypeStatisticTotal = contractData.statisticTotal;
      this.buildingContractExcelData = contractData.excelData;

      const zuersData = getStatisticData(data.zuers, 'zurs_type');

      this.buildingZursStatisticValues = zuersData.values;
      this.buildingZursStatisticLabels = zuersData.labels;
      this.buildingZursStatisticTotal = zuersData.statisticTotal;
      this.buildingZursExcelData = zuersData.excelData;

      const building5155Data = getStatisticData(data.building5155, groupKey);

      this.buildingStatisticValues5155 = building5155Data.values;
      this.buildingStatisticLabels5155 = building5155Data.labels;
      this.buildingStatisticTotal5155 = building5155Data.statisticTotal;
      this.building5155ExcelData = building5155Data.excelData;

      const building5180Data = getStatisticData(data.building5180, groupKey);

      this.buildingStatisticValues5180 = building5180Data.values;
      this.buildingStatisticLabels5180 = building5180Data.labels;
      this.buildingStatisticTotal5180 = building5180Data.statisticTotal;
      this.building5180ExcelData = building5180Data.excelData;

      const building5181Data = getStatisticData(data.building5181, groupKey);

      this.buildingStatisticValues5181 = building5181Data.values;
      this.buildingStatisticLabels5181 = building5181Data.labels;
      this.buildingStatisticTotal5181 = building5181Data.statisticTotal;
      this.building5181ExcelData = building5181Data.excelData;

      const altbauData = getStatisticData(data.altbau, groupKey);

      this.buildingStatisticValuesAltbau = altbauData.values;
      this.buildingStatisticLabelsAltbau = altbauData.labels;
      this.buildingStatisticTotalAltbau = altbauData.statisticTotal;
      this.buildingAltbauExcelData = altbauData.excelData;

      this.statisticLoaded = true;
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectFilterType);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectFilterType][key]) {
        this.selectFilterType = this.selectOptions[0].value;
      }
    },
    prepareDateFilterParams(): dateFilterParamsType {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      return {
        from,
        to,
      };
    },
  },
});
