






import Vue from 'vue';
import Login from './login.vue';
import Registration from './registration.vue';
import ResetPassword from './reset-password.vue';
import HomeMain from './home-main.vue';

export default Vue.extend({
  props: ['OnLoggedIn'],
  name: 'Home',
  components: {
    ResetPassword,
    Registration,
    Login,
    HomeMain,
  },
  data: () => ({
    currentPage: 'login',
    isLoggedIn: false,
  }),
  methods: {
    onFormChange(value: string) {
      this.currentPage = value;
    },
    setIsLoggedIn(status: boolean) {
      this.isLoggedIn = status;
      this.$emit('LoggedIn', status);
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.currentPage = 'home-main';
    } else {
      this.currentPage = 'login';
    }
    // this.$emit('LoggedIn', true);
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  },
});
