import { render, staticRenderFns } from "./wind-turbines-chart.vue?vue&type=template&id=099da23b&scoped=true&"
import script from "./wind-turbines-chart.vue?vue&type=script&lang=ts&"
export * from "./wind-turbines-chart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "099da23b",
  null
  
)

export default component.exports