import { AuthResponse } from '@/model/auth';
import { USER_ROLE_API_USER, USER_ROLE_PCU_USER, USER_ROLE_SUPER_ADMIN } from '@/constant/auth-constants';

const authModule = {
  namespaced: true,
  state: {
    user: null as AuthResponse | null,
  },
  getters: {
    getUser: (state) => state.user,
    isSuperAdminUser: (state) => state.user.roles.indexOf(USER_ROLE_SUPER_ADMIN) > -1 as boolean,
    isApiUser: (state) => state.user.roles.indexOf(USER_ROLE_API_USER) > -1 as boolean,
    isPCUser: (state) => state.user.roles.indexOf(USER_ROLE_PCU_USER) > -1 as boolean,
  },
  mutations: {
    setUser(state, user: AuthResponse | null) {
      state.user = user;
    },
  }
};

export default authModule;
