import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/home.vue';
import Statistic from '@/views/statistic.vue';
import priceCalculator from '@/service/api-insurance';
import resetPasswordConfirmation from '@/views/reset-password-confirmation.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: Statistic,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: resetPasswordConfirmation,
  },
  {
    path: '/get-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'get-insurance'
    },
  },
  {
    path: '/individual-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'individual-insurance'
    },
    children: [
      {
        name: 'individual-insurance',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/individual-insurance-basic.vue'
          ),
      },
      {
        name: 'individual-insurance-calc',
        path: 'calc',
        component: () =>
          import(
            '../views/insurance-forms/individual-insurance-calc.vue'
          ),
      },
      {
        name: 'machines-power-plants-calc',
        path: 'machines-power-plants-calc',
        component: () =>
          import(
            '../views/insurance-forms/machines-power-plants-calc.vue'
          ),
      },
      {
        name: 'individual-insurance-risk',
        path: 'risk',
        component: () =>
          import(
            '../views/insurance-forms/individual-insurance-risk.vue'
          ),
      },
      {
        name: 'individual-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      // {
      //   name: 'individual-insurance-proposal-policy',
      //   path: 'proposal-policy',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
      //     ),
      // },
      // {
      //   name: 'individual-insurance-customer-general',
      //   path: 'customer-general',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
      //     ),
      // },
      // {
      //   name: 'individual-insurance-customer-data',
      //   path: 'customer-data',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
      //     ),
      // },
      // {
      //   name: 'individual-insurance-conditions-files',
      //   path: 'conditions',
      //   component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      // },
    ]
  },
  {
    path: '/bauleistung-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'bauleistung-insurance'
    },
    children: [
      {
        name: 'bauleistung-insurance',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/bauleistung-insurance-basic.vue'
          ),
      },
      {
        name: 'bauleistung-calc-2a-ABN',
        path: 'calc-2a-ABN',
        component: () =>
          import(
            '../views/insurance-forms/bauleistung-calc-2a-ABN.vue'
          ),
      },
      {
        name: 'bauleistung-calc-2b-ABU',
        path: 'calc-2b-ABU',
        component: () =>
          import(
            /* webpackChunkName: "bauleistung-calc" */ '../views/insurance-forms/bauleistung-calc-2b-ABU.vue'
          ),
      },
      {
        name: 'bauleistung-insurance-risk',
        path: 'risk',
        component: () =>
          import(
            /* webpackChunkName: "bauleistung-risk" */'../views/insurance-forms/bauleistung-insurance-risk.vue'
          ),
      },
      {
        name: 'bauleistung-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/bauleistung-price-card.vue'),
      },
      // {
      //   name: 'bauleistung-insurance-proposal-policy',
      //   path: 'proposal-policy',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
      //     ),
      // },
      // {
      //   name: 'bauleistung-insurance-customer-general',
      //   path: 'customer-general',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
      //     ),
      // },
      // {
      //   name: 'bauleistung-insurance-customer-data',
      //   path: 'customer-data',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
      //     ),
      // },
      // {
      //   name: 'bauleistung-insurance-conditions-files',
      //   path: 'conditions',
      //   component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      // },
    ]
  },
  {
    path: '/elektronik-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'elektronik-insurance'
    },
    children: [
      {
        name: 'elektronik-insurance',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/elektronik-insurance-basic.vue'
          ),
      },
      {
        name: 'elektronik-insurance-ELEKEinzel',
        path: 'ELEKEinzel',
        component: () =>
          import('../views/insurance-forms/elektronik-insurance-ELEKEinzel.vue'),
      },
      {
        name: 'elektronik-insurance-ELEKErganzungstarif',
        path: 'ELEKErganzungstarif',
        component: () =>
          import('../views/insurance-forms/elektronik-insurance-ELEKErganzungstarif.vue'),
      },
      {
        name: 'elektronik-insurance-ELEKPauschal',
        path: 'ELEKPauschal',
        component: () =>
          import('../views/insurance-forms/elektronik-insurance-ELEKPauschal.vue'),
      },
      {
        name: 'elektronik-insurance-Praxispolice',
        path: 'Praxispolice',
        component: () =>
          import('../views/insurance-forms/elektronik-insurance-Praxispolice.vue'),
      },
      {
        name: 'elektronik-insurance-risk',
        path: 'risk',
        component: () =>
          import('../views/insurance-forms/elektronik-insurance-risk.vue'),
      },
      {
        name: 'elektronik-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/elektronik-price-card.vue'),
      },
      // {
      //   name: 'elektronik-insurance-proposal-policy',
      //   path: 'proposal-policy',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
      //     ),
      // },
      // {
      //   name: 'elektronik-insurance-customer-general',
      //   path: 'customer-general',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
      //     ),
      // },
      // {
      //   name: 'elektronik-insurance-customer-data',
      //   path: 'customer-data',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
      //     ),
      // },
      // {
      //   name: 'elektronik-insurance-conditions-files',
      //   path: 'conditions',
      //   component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      // },
    ]
  },
  {
    path: '/windenergieanlagen-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'windenergieanlagen-insurance'
    },
    children: [
      {
        name: 'windenergieanlagen-insurance',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/windenergieanlagen-insurance-basic.vue'
          ),
      },
      {
        name: 'windenergieanlagen-insurance-tarif',
        path: 'tarif',
        component: () =>
          import('../views/insurance-forms/windenergieanlagen-insurance-tarif.vue'),
      },
      {
        name: 'windenergieanlagen-insurance-price-card',
        path: 'price-card',
        component: () =>
          import('../views/insurance-forms/windenergieanlagen-price-card.vue'),
      },
      // {
      //   name: 'windenergieanlagen-insurance-proposal-policy',
      //   path: 'proposal-policy',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
      //     ),
      // },
      // {
      //   name: 'windenergieanlagen-insurance-customer-general',
      //   path: 'customer-general',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
      //     ),
      // },
      // {
      //   name: 'windenergieanlagen-insurance-customer-data',
      //   path: 'customer-data',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
      //     ),
      // },
      // {
      //   name: 'windenergieanlagen-insurance-conditions-files',
      //   path: 'conditions',
      //   component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      // },
    ]
  },
  {
    path: '/biogas-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'biogas-insurance'
    },
    children: [
      {
        name: 'biogas-insurance',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/biogas-insurance-basic.vue'
          ),
      },
      {
        name: 'biogas-insurance-tarif',
        path: 'tarif',
        component: () =>
          import('../views/insurance-forms/biogas-insurance-tarif.vue'),
      },
      {
        name: 'biogas-insurance-risk',
        path: 'risk',
        component: () =>
          import('../views/insurance-forms/biogas-insurance-risk.vue'),
      },
      {
        name: 'biogas-insurance-price-card',
        path: 'price-card',
        component: () =>
          import('../views/insurance-forms/biogas-insurance-price.vue'),
      },
      {
        name: 'biogas-insurance-results-page',
        path: 'results-page',
        component: () =>
          import('../views/insurance-forms/biogas-insurance-results-page.vue'),
      },
      // {
      //   name: 'biogas-insurance-proposal-policy',
      //   path: 'proposal-policy',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
      //     ),
      // },
      // {
      //   name: 'biogas-insurance-customer-general',
      //   path: 'customer-general',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
      //     ),
      // },
      // {
      //   name: 'biogas-insurance-customer-data',
      //   path: 'customer-data',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
      //     ),
      // },
      // {
      //   name: 'biogas-insurance-conditions-files',
      //   path: 'conditions',
      //   component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      // },
    ]
  },

  {
    path: '/assembly-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'assembly-insurance'
    },
    children: [
      {
        name: 'assembly-insurance',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/assembly-insurance-basic.vue'
          ),
      },
      {
        name: 'assembly-insurance-tarif',
        path: 'tarif',
        component: () =>
          import(
            '../views/insurance-forms/assembly-insurance-tarif.vue'
          ),
      },
      {
        name: 'assembly-insurance-price-card',
        path: 'price-card',
        component: () =>
          import('../views/insurance-forms/assembly-price-card.vue'),
      },
      // {
      //   name: 'assembly-insurance-proposal-policy',
      //   path: 'proposal-policy',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
      //     ),
      // },
      // {
      //   name: 'assembly-insurance-customer-general',
      //   path: 'customer-general',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
      //     ),
      // },
      // {
      //   name: 'assembly-insurance-customer-data',
      //   path: 'customer-data',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
      //     ),
      // },
      // {
      //   name: 'assembly-insurance-conditions-files',
      //   path: 'conditions',
      //   component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      // },
    ]
  },
  {
    path: '/photovoltaics-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'photovoltaics-insurance'
    },
    children: [
      {
        name: 'photovoltaics-basic',
        path: '',
        component: () =>
          import(
            '../views/insurance-forms/photovoltaics-basic.vue'
          ),
      },
      {
        name: 'photovoltaics-insurance-EEPV',
        path: 'eepv',
        component: () =>
          import(
            '../views/insurance-forms/photovoltaics-insurance-EEPV.vue'
          ),
      },
      {
        name: 'photovoltaics-insurance-BU-ABE',
        path: 'bu-abe',
        component: () =>
          import(
            '../views/insurance-forms/photovoltaics-insurance-BU-ABE.vue'
          ),
      },
      {
        name: 'photovoltaics-insurance-EEPV-ABPV',
        path: 'eepv-abpv',
        component: () =>
          import(
            '../views/insurance-forms/photovoltaics-insurance-EEPV-ABPV.vue'
          ),
      },
      {
        name: 'photovoltaics-insurance-risk',
        path: 'risk',
        component: () =>
          import(
            '../views/insurance-forms/photovoltaics-insurance-risk.vue'
          ),
      },
      {
        name: 'photovoltaics-price-card',
        path: 'price',
        component: () =>
          import(
            '../views/insurance-forms/photovoltaics-price-card.vue'
          ),
      },
    ]
  },


  {
    path: '/signature-pad/:id',
    component: () => import(/* webpackChunkName: "signature-pad" */ '../views/signature.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/products.vue'),
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import(/* webpackChunkName: "offers"  */ '../views/offers.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});


function getAccessProducts(key: string) {
  if (key === '') {
    return true;
  }
  return priceCalculator.getPermissionProduct(key);
}

function getAccessProductKey(name: string | null | undefined) {
  let key = '';
  switch (name) {
    case 'individual-insurance':
      key = 'INDIVIDUAL_INSURANCE';
      break;
    default:
      break;
  }
  return key;
}

router.beforeEach((to, from, next) => {
  const res = getAccessProducts(getAccessProductKey(to.name));
  if (typeof res !== 'boolean') {
    res.then((response) => {
      if (!response.data) {
        next({ name: 'products' });
      }
    });
  }
  if (!res) {
    next({ name: 'products' });
  } else next();
});

export default router;
