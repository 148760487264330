import { render, staticRenderFns } from "./products-chart.vue?vue&type=template&id=624009c7&scoped=true&"
import script from "./products-chart.vue?vue&type=script&lang=ts&"
export * from "./products-chart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624009c7",
  null
  
)

export default component.exports