



























































































































































































































































































































































import Vue from 'vue';
import DatePicker from '@/components/form/date-picker.vue';
import { dateService } from '@/service/date-service';
import apiBackend from '@/service/api-insurance';
import { getStatisticData } from '@/helper/functions';

type dateFilterParamsType = {
  from: string;
  to: string;
}

export default Vue.extend({
  name: 'WindTurbinesChart',
  props: {
    pdfExportStyle: {
      type: Boolean,
      default: false,
    },
    statisticData: {
      type: Object,
      required: false,
      default: null,
    },
    showCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      byTypeStatisticValues: [] as number[],
      byTypeStatisticLabels: [] as string[],
      byTypeStatisticTotal: 0,
      byTypeFileName: 'Art der WEA',
      statisticLoaded: false,
      typeExcelData: [] as object[],

      byCoverageStatisticValues: [] as number[],
      byCoverageStatisticLabels: [] as string[],
      byCoverageStatisticTotal: 0,
      byCoverageFileName: 'Deckungsform',
      coverageExcelData: [] as object[],

      byTimeStatisticValues: [] as number[],
      byTimeStatisticLabels: [] as string[],
      byTimeStatisticTotal: 0,
      byTimeFileName: 'Haftzeit BU',
      timeExcelData: [] as object[],

      byPlantsNumberStatisticValues: [] as number[],
      byPlantsNumberStatisticLabels: [] as string[],
      byPlantsNumberStatisticTotal: 0,
      byPlantsNumberTimeFileName: 'Anzahl der Anlagen',
      plantsNumberTimeExcelData: [] as object[],

      bySbBuStatisticValues: [] as number[],
      bySbBuStatisticLabels: [] as string[],
      bySbBuStatisticTotal: 0,
      bySbBuFileName: 'SB BU',
      sbBuExcelData: [] as object[],

      byPaymentTypeStatisticValues: [] as number[],
      byPaymentTypeStatisticLabels: [] as string[],
      byPaymentTypeStatisticTotal: 0,
      byPaymentTypeFileName: 'Zahlungsweise',
      paymentTypeExcelData: [] as object[],

      selectFilterType: 'all',
      from: '',
      to: '',
    };
  },
  computed: {
    loadDataDates(): string {
      return this.from.toString() + this.to.toString();
    },
    exportExcelData(): object {
      return {
        type: this.typeExcelData,
        coverage: this.coverageExcelData,
        time: this.timeExcelData,
        plantsNumberTime: this.plantsNumberTimeExcelData,
        sbBuExcelData: this.sbBuExcelData,
        paymentTypeExcelData: this.paymentTypeExcelData,
      };
    },
    selectOptions() {
      return [
        {
          text: 'Gesamte Zeit', value: 'all',
        },
        {
          text: 'Dieses Jahr', value: 'year',
        },
        {
          text: 'Dieser Monat', value: 'month',
        },
        {
          text: 'Diese Woche', value: 'week',
        },
        {
          text: 'Heute', value: 'day',
        },
      ];
    },
    preselectDates() {
      return {
        all: {
          from: '',
          to: dateService.getCurrentDate(),
        },
        year: {
          from: dateService.getStartOfYearDate(),
          to: dateService.getEndOfYearDate(),
        },
        month: {
          from: dateService.getStartOfMonthDate(),
          to: dateService.getEndOfMonthDate(),
        },
        week: {
          from: dateService.getStartOfWeekDate(),
          to: dateService.getEndOfWeekDate(),
        },
        day: {
          from: dateService.getStartOfDayDate(),
          to: dateService.getEndOfDayDate(),
        },
      };
    },
  },
  mounted() {
    if (this.statisticData) {
      this.importStatisticData(this.statisticData);
    } else {
      this.selectFilterType = this.selectOptions[0].value;
      this.selectPeriodDate();
    }
  },
  watch: {
    loadDataDates() {
      this.getStatistic();
    },
    statisticLoaded(value: boolean) {
      if (value) {
        this.$emit('on-loaded-all-data', true, this.exportExcelData);
      }
    },
  },
  methods: {
    getStatistic() {
      const filterParams = this.prepareDateFilterParams();

      apiBackend.windStatistic(filterParams.from, filterParams.to).then((response: any) => {
        this.importStatisticData(response.data);
      });
    },
    importStatisticData(data: any) {
      const groupKey = 'wind_type';

      const typeData = getStatisticData(data.type, groupKey);

      this.byTypeStatisticValues = typeData.values;
      this.byTypeStatisticLabels = typeData.labels;
      this.byTypeStatisticTotal = typeData.statisticTotal;
      this.typeExcelData = typeData.excelData;

      const coverageData = getStatisticData(data.coverage, groupKey);

      this.byCoverageStatisticValues = coverageData.values;
      this.byCoverageStatisticLabels = coverageData.labels;
      this.byCoverageStatisticTotal = coverageData.statisticTotal;
      this.coverageExcelData = coverageData.excelData;

      const timeData = getStatisticData(data.time, groupKey);

      this.byTimeStatisticValues = timeData.values;
      this.byTimeStatisticLabels = timeData.labels;
      this.byTimeStatisticTotal = timeData.statisticTotal;
      this.timeExcelData = timeData.excelData;

      const plantsNumberData = getStatisticData(data.plantsNumber, groupKey);

      this.byPlantsNumberStatisticValues = plantsNumberData.values;
      this.byPlantsNumberStatisticLabels = plantsNumberData.labels;
      this.byPlantsNumberStatisticTotal = plantsNumberData.statisticTotal;
      this.plantsNumberTimeExcelData = plantsNumberData.excelData;

      const sbBuData = getStatisticData(data.sbBu, groupKey);

      this.bySbBuStatisticValues = sbBuData.values;
      this.bySbBuStatisticLabels = sbBuData.labels;
      this.bySbBuStatisticTotal = sbBuData.statisticTotal;
      this.sbBuExcelData = sbBuData.excelData;

      const paymentTypeData = getStatisticData(data.paymentType, groupKey);

      this.byPaymentTypeStatisticValues = paymentTypeData.values;
      this.byPaymentTypeStatisticLabels = paymentTypeData.labels;
      this.byPaymentTypeStatisticTotal = paymentTypeData.statisticTotal;
      this.paymentTypeExcelData = paymentTypeData.excelData;

      this.statisticLoaded = true;
    },
    selectPeriodDate() {
      const { from, to } = this.getFromToPreselect(this.selectFilterType);

      this.from = from;
      this.to = to;
    },
    getFromToPreselect(key) {
      return this.preselectDates[key];
    },
    onSelectDateFrom(value) {
      this.onSelectDate(value, 'from');
    },
    onSelectDateTo(value) {
      this.onSelectDate(value, 'to');
    },
    onSelectDate(value, key) {
      this[key] = value;

      if (value !== this.preselectDates[this.selectFilterType][key]) {
        this.selectFilterType = this.selectOptions[0].value;
      }
    },
    prepareDateFilterParams(): dateFilterParamsType {
      const fromDate = this.from as any;
      const toDate = this.to as any;

      const from = fromDate instanceof Date ? dateService.toStringSqlFormat(fromDate) : '';
      const to = toDate instanceof Date ? dateService.toStringSqlFormat(toDate) : '';

      return {
        from,
        to,
      };
    },
  },
});
